import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { Link } from 'react-router-dom';
import "./header.css";

function OffcanvasExample() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      {[false].map((expand) => (
        <Navbar key={expand} bg="light" expand={expand} className="mb-3">
          <Container fluid>
            <Navbar.Brand href="#">Vahan Sarthi</Navbar.Brand>
            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} onClick={handleShow} />
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-${expand}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
              placement="end"
              show={show}
              onHide={handleClose}
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                  Menu
                </Offcanvas.Title>
              </Offcanvas.Header>
              <hr />
              <Offcanvas.Body>
                <Nav className="justify-content-end flex-grow-1 pe-4">
                  <Nav.Link as={Link} to="/home" onClick={handleClose}>Home</Nav.Link>
                  <Nav.Link as={Link} to="/salesReport" onClick={handleClose}>Sales Report</Nav.Link>
                  <Nav.Link as={Link} to="/vahanDoc" onClick={handleClose}>Doc</Nav.Link>
                  <Nav.Link as={Link} to="/newBooking" onClick={handleClose}>Booking</Nav.Link>

                  <NavDropdown title="Servicing" id={`offcanvasNavbarDropdown-expand-${expand}`}>
                    <NavDropdown.Item as={Link} to="/vehicleServicing" onClick={handleClose}>Servicing</NavDropdown.Item>
                    <NavDropdown.Item as={Link} to="/vehicleServicingHistory" onClick={handleClose}>Servicing History</NavDropdown.Item>
                  </NavDropdown>
                  
                  <NavDropdown title="Tyre" id={`offcanvasNavbarDropdown-expand-${expand}`}>
                    <NavDropdown.Item as={Link} to="/TyreEntry" onClick={handleClose}>New Registration</NavDropdown.Item>
                    <NavDropdown.Item as={Link} to="/TyreJourney" onClick={handleClose}>Tyre Life Cycle</NavDropdown.Item>
                  </NavDropdown>

                  <NavDropdown title="Bus" id={`offcanvasNavbarDropdown-expand-${expand}`}>
                    <NavDropdown.Item as={Link} to="/BusEntry" onClick={handleClose}>Bus RC</NavDropdown.Item>
                    <NavDropdown.Item as={Link} to="/BusRoute" onClick={handleClose}>Route</NavDropdown.Item>
                  </NavDropdown>

                  <Nav.Link as={Link} to="/EmployeeRegistration" onClick={handleClose}>Employee Registration</Nav.Link>
                  <Nav.Link as={Link} to="/gpsDashboard" onClick={handleClose}>GPS</Nav.Link>
                  
                  <Nav.Link as={Link} to="/feedback" onClick={handleClose}>Feedback</Nav.Link>
                  <Nav.Link as={Link} to="/profile" onClick={handleClose}>Profile</Nav.Link>
                  <Nav.Link as={Link} to="/" onClick={handleClose}>Logout</Nav.Link>
                </Nav>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
      ))}
    </>
  );
}

export default OffcanvasExample;

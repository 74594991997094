import React, { useState } from 'react';
import Authuser from './Authuser'; // Assuming Authuser provides methods for login
import { Link } from 'react-router-dom';
import Loader from './Loader'; // Import your loader component

function Login() {
  const { http, setToken } = Authuser();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null); // State to store login error message
  const [loading, setLoading] = useState(false); // State to track loading state

  const submitForm = async () => {
    setLoading(true); // Set loading state to true when login request starts
    setError(null); // Clear any previous errors
    try {
      const response = await http.post('/user_login', {
        user_id: username,
        password: password,
      });
      if (response) {
        setToken(response.data.access_token);
      } else {
        console.log("error")
      }
    } catch (error) {
      if (error.response && error.response.data) {
        setError(error.response.data.error || 'Login failed. Please check your credentials.');
      } else {
        setError('An error occurred during login. Please try again later.');
      }
    }
    setLoading(false); // Set loading state to false after login request completes
  };

  return (
    <div className='container'>
      <div className="row justify-content-center" style={{ marginTop: "10%" }}>
        <div className="col-md-5">
          <div className="row">
            <div className="col-md-12" style={{ textAlign: "center" }}>
              <img src={require('../images/admin.png')} alt="admin" height={50} width={50} />
            </div>
            <br /><br />
            <div className="col-mb-12" style={{ margin: "5px" }}>
              <input
                type="text"
                className="form-control"
                onChange={(e) => setUsername(e.target.value)}
                id="username"
                aria-describedby="username"
                placeholder='UserName'
              />
            </div>
            <br />
            <div className="col-md-12" style={{ margin: "5px" }}>
              <input
                type="password"
                className="form-control"
                onChange={(e) => setPassword(e.target.value)}
                id="pwd"
                placeholder='PassWord'
              />
            </div>
            <br />
            {error && (
              <div className="alert alert-danger" role="alert">
                {error}
              </div>
            )}
            {loading ? ( // Show loader if loading state is true
              <Loader />
            ) : (
              <button type="button" onClick={submitForm} className="btn btn-primary" style={{ margin: "5px" }}>
                Login
              </button>
            )}
            <div style={{ textAlign: "center", color: "blue", marginTop: "20px" }}>
              <Link to="/signUp">don't have account! Create Now</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import 'react-datepicker/dist/react-datepicker.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FaCalendarAlt } from "react-icons/fa";
import './Sales.css';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Table, Button } from 'react-bootstrap';

import { FaEdit } from "react-icons/fa";
import { FaTrash } from "react-icons/fa";
import { format } from 'date-fns';
import AuthUser from './Authuser';
import { useNavigate } from 'react-router-dom';
import Loader from './Loader';

const Profile = () => {
    const { token, refreshToken, http, logout } = AuthUser();
    const navigate = useNavigate();

    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (!token) {
            // Redirect to login if no token
            navigate('/userlogin');
        }
    }, [token, navigate]);

    const fetchData = async () => {
        try {
            const response = await http.get('/api_userProfile');
            const resData = response.data;
            setData(resData);
            setIsLoading(false);
            console.log(resData)
        } catch (error) {
            console.log(error);
            navigate("/");
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    if (isLoading) {
        return <Loader />;
    }

    if (data.length === 0) {
        return <div>No Profile Data Found</div>;
    }

    return (
        <div className="container-fluid" style={{textAlign:"center"}}>
            {data.map((item, index) => (
                <Row key={index} className='justify-content-center'>
                    
                    <Col md="6">
                        <Table>
                            <thead></thead>
                            <tbody>
                                <tr>
                                    <th>Company Name</th>
                                    <td>{item.companyName}</td>
                                </tr>
                                <tr>
                                    <th>Contact No</th>
                                    <td>{item.contactNo}</td>
                                </tr>
                                <tr>
                                    <th>Email</th>
                                    <td>{item.email}</td>
                                </tr>
                                <tr>
                                    <th>Address</th>
                                    <td>{item.cAddress}</td>
                                </tr>
                                <tr>
                                    <th>Joining Date</th>
                                    <td>{item.rDate}</td>
                                </tr>
                                <tr>
                                    <th>Username</th>
                                    <td>{item.userId}</td>
                                </tr>
                                <tr>
                                    <th>Password</th>
                                    <td>{item.password}</td>
                                </tr>
                               
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            ))}
        </div>
    );
}

export default Profile;

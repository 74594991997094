import axios from 'axios';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

export default function AuthUser() {
    const navigate = useNavigate();

    const getToken = () => {
        const tokenString = sessionStorage.getItem('token');
        const userToken = JSON.parse(tokenString);
        return userToken?.token; // Return only the token value
    }

    

    const [token, setToken] = useState(getToken());
   
    const saveToken = (token, refreshToken) => {
        sessionStorage.setItem('token', JSON.stringify({ token }));
        

        setToken(token);
        
        navigate('/home');
    }

    const logout = () => {
        sessionStorage.clear();
        navigate('/');
    }

    const http = axios.create({
        baseURL: "https://vahansarthi.com/",
        headers: {
            "Content-type": "application/json",
            "Authorization": `Bearer ${token}` // Include the token in the Authorization header
        }
    });
    /*
    http.interceptors.response.use(
        (response) => response,
        async (error) => {
            const originalRequest = error.config;

            if (error.response.status === 401 && !originalRequest._retry) {
                originalRequest._retry = true;

                const refreshToken = getUser()?.refreshToken;
                if (!refreshToken) {
                    logout(); // Redirect to login if refresh token is not available
                    return Promise.reject(error);
                }

                try {
                    const response = await axios.post('https://vahansarthi.com/refresh_api', { refreshToken });
                    const { token: newToken } = response.data;

                    saveToken(newToken, refreshToken); // Update the token
                    originalRequest.headers['Authorization'] = `Bearer ${newToken}`;

                    return http(originalRequest); // Retry the original request with the new token
                } catch (refreshError) {
                    logout(); // Redirect to login if refresh token fails
                    return Promise.reject(refreshError);
                }
            }

            return Promise.reject(error);
        }
    );
    */
    return {
        setToken: saveToken,
        token,
        
        getToken,
        http,
        logout
    };
}

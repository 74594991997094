import React, { useEffect, useState } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FaEdit, FaTrash } from "react-icons/fa";
import { FcSynchronize } from "react-icons/fc";
import './Sales.css';
import { format } from 'date-fns';

import { Row, Col, Table, Button, Modal } from 'react-bootstrap';
import AuthUser from './Authuser';
import { useNavigate } from 'react-router-dom';

const VahanDoc = () => {
  const { token, refreshToken, http, logout } = AuthUser();
  const navigate = useNavigate();

  useEffect(() => {
    if (!token) {
      navigate('/userlogin');
    }
  }, [token, navigate]);

  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
    const day = String(today.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [error, setError] = useState(null);
  const [isData, setIsData] = useState(true);

  const [vehicleNo, setVehicleNo] = useState('');

  const [insurance, setInsurance] = useState(getCurrentDate());
  const [pollution, setPollution] = useState(getCurrentDate());
  const [fitness, setFitness] = useState(getCurrentDate());
  const [roadTax, setRoadTax] = useState(getCurrentDate());
  const [retroTape, setRetroTape] = useState(getCurrentDate());
  const [sld, setSld] = useState(getCurrentDate());
  const [permit, setPermit] = useState(getCurrentDate());
  const [cSign, setCsign] = useState(getCurrentDate());

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [id, setId] = useState('');

  const [loading, setLoading] = useState(false);
  const [res, setRes] = useState(false);

  const fetchData = async () => {
    try {
      const response = await http.get('/api_doc');
      const resData = await response.data;
      setIsData(false);
      setData(resData);
      setFilteredData(resData);
    } catch (error) {
      setError(error);
      setIsData(false);
      navigate('/');
    }
  };

  const handleSearch = async (e) => {
    e.preventDefault();
    try {
      const response = await http.get(`/api_doc_filter/VS162/bus_no/${vehicleNo}`);
      const resData = await response.data;
      setIsData(false);
      setFilteredData(resData);
    } catch (error) {
      setError(error);
      setIsData(false);
      navigate('/');
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (res) {
      const timer = setTimeout(() => {
        setRes(false);
      }, 5000); // Clear the result message after 5 seconds

      return () => clearTimeout(timer); // Cleanup the timer on component unmount
    }
  }, [res]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await http.post('/api_doc_insert', {
        vehicleNo,
        insurance,
        pollution,
        fitness,
        roadTax,
        retroTape,
        sld,
        permit,
        cSign
      });
      console.log(response.data);
      setLoading(false);
      setVehicleNo('');
      setRes(true);
      fetchData();
    } catch (error) {
      console.error('Error submitting sales entry:', error);
      setLoading(false);
      setRes('Error submitting data');
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await http.put(`/api_doc_update/VS162/${vehicleNo}`, {
        id,
        vehicleNo,
        insurance,
        pollution,
        fitness,
        roadTax,
        retroTape,
        sld,
        permit,
        cSign,
        busType: "1",
        busRoute: "1"
      });
      console.log(response.data);
      setLoading(false);
      setVehicleNo('');
      setRes("data updated");
      fetchData();
    } catch (error) {
      console.error('Error submitting sales entry:', error);
      setLoading(false);
      setRes('Error submitting data');
    }
  };

  const fetchDeleteData = async (index) => {
    const docDataToDelete = data[index];
    setVehicleNo(docDataToDelete.vehicleNo);
    handleShow();
  };

  const handleDelete = async () => {
    setLoading(true);
    try {
      const response = await http.delete(`/api_doc_delete/VS/${vehicleNo}`);
      console.log('doc data deleted successfully:', response.data);
      setLoading(false);
      setRes('Sales data deleted');
      fetchData();
      setVehicleNo('');
      handleClose();
    } catch (error) {
      console.error('Error deleting sales data:', error);
      if (error.response) {
        console.error('Server error:', error.response.data);
        setRes('Error deleting data (server error)');
      } else if (error.request) {
        console.error('Network error:', error.request);
        setRes('Error deleting data (network error)');
      } else {
        console.error('Error:', error.message);
        setRes('Error deleting data (unknown error)');
      }
      setLoading(false);
    }
  };

  const formatDate = (dateString) => {
    const [day, month, year] = dateString.split('/');
    const date = new Date(`${year}-${month}-${day}`);
    return format(date, 'yyyy-MM-dd');
  };

  const handleFetchData = (index) => {
    const selectedData = filteredData[index];
    setVehicleNo(selectedData.vehicleNo);
    setId(selectedData.id);
    setInsurance(formatDate(selectedData.insurance));
    setPollution(formatDate(selectedData.pollution));
    setFitness(formatDate(selectedData.fitness));
    setRoadTax(formatDate(selectedData.roadTax));
    setRetroTape(formatDate(selectedData.retroTape));
    setPermit(formatDate(selectedData.permit));
    setCsign(formatDate(selectedData.cSign));
    setSld(formatDate(selectedData.sld));
  };

  if (isData) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <div className="container-fluid">
      <div>
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Are you sure to delete this {vehicleNo} data</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p><Button onClick={handleDelete} style={{ backgroundColor: "red" }}>DELETE</Button></p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>Close</Button>
          </Modal.Footer>
        </Modal>
      </div>
      <Row className="justify-content-md-center">
        <Col md='3'>
          <div className='container'>
            <h3>Document expiry date</h3>
            <form onSubmit={handleSubmit}>
              <table>
                <tbody className='salesEntry'>
                  <tr>
                    <td>Vehicle No:</td>
                    <td>
                      <input
                        type="text"
                        name="vehicleNo"
                        pattern="[A-Z0-9]+"
                        value={vehicleNo}
                        onChange={(e) => setVehicleNo(e.target.value.toUpperCase())}
                        required
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Insurance</td>
                    <td>
                      <input type="date" name='insurance' value={insurance} onChange={e => setInsurance(e.target.value)} />
                    </td>
                  </tr>
                  <tr>
                    <td>Pollution</td>
                    <td>
                      <input type="date" name='pollution' value={pollution} onChange={e => setPollution(e.target.value)} />
                    </td>
                  </tr>
                  <tr>
                    <td>Fitness</td>
                    <td>
                      <input type="date" name='fitness' value={fitness} onChange={e => setFitness(e.target.value)} />
                    </td>
                  </tr>
                  <tr>
                    <td>Road Tax</td>
                    <td>
                      <input type="date" name='roadTax' value={roadTax} onChange={e => setRoadTax(e.target.value)} />
                    </td>
                  </tr>
                  <tr>
                    <td>Retro Tape</td>
                    <td>
                      <input type="date" name='retroTape' value={retroTape} onChange={e => setRetroTape(e.target.value)} />
                    </td>
                  </tr>
                  <tr>
                    <td>Permit</td>
                    <td>
                      <input type="date" name='permit' value={permit} onChange={e => setPermit(e.target.value)} />
                    </td>
                  </tr>
                  <tr>
                    <td>SLD</td>
                    <td>
                      <input type="date" name='sld' value={sld} onChange={e => setSld(e.target.value)} />
                    </td>
                  </tr>
                  <tr>
                    <td>C Sign</td>
                    <td>
                      <input type="date" name='cSign' value={cSign} onChange={e => setCsign(e.target.value)} />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Button variant="primary" type="submit" disabled={loading}>{loading ? 'Loading...' : 'Submit'}</Button>
                    </td>
                  </tr>
                  <tr>
                    <td>{res ? <div className="result-message">{res}</div> : null}</td>
                  </tr>
                </tbody>
              </table>
            </form>
          </div>
        </Col>
        <Col md='9'>
          <div className='container'>
            <div className='row'>
              <div className='col-md-6'>
                <h2>Document details</h2>
              </div>
              <div className='col-md-6'>
                <form onSubmit={handleSearch} className="form-inline my-2 my-lg-0">
                  <div className="input-group">
                    <input
                      className="form-control mr-sm-2"
                      type="text"
                      value={vehicleNo}
                      onChange={(e) => setVehicleNo(e.target.value.toUpperCase())}
                      placeholder="Search"
                    />
                    <button className="btn btn-outline-success my-2 my-sm-0" type="submit">
                      Search
                    </button>
                  </div>
                </form>
              </div>
            </div>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th></th>
                  <th>Vehicle No</th>
                  <th>Insurance</th>
                  <th>Pollution</th>
                  <th>Fitness</th>
                  <th>Road Tax</th>
                  <th>Retro Tape</th>
                  <th>SLD</th>
                  <th>Permit</th>
                  <th>C Sign</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {filteredData.length === 0 ? (
                  <tr>
                    <td colSpan="11" className="text-center">
                      No data available
                    </td>
                  </tr>
                ) : (
                  filteredData.map((entry, index) => (
                    <tr key={index}>
                      <td style={{visibility:'hidden'}}>{entry.id}</td>
                      <td>{entry.vehicleNo}</td>
                      <td style={{ backgroundColor: entry.insuranceExpiry ? 'lightgreen' : '#FFA27F' }}>{entry.insurance}</td>
                      <td style={{ backgroundColor: entry.pollutionExpiry ? 'lightgreen' : '#FFA27F' }}>{entry.pollution}</td>
                      <td style={{ backgroundColor: entry.fitnessExpiry ? 'lightgreen' : '#FFA27F' }}>{entry.fitness}</td>
                      <td style={{ backgroundColor: entry.sldExpiry ? 'lightgreen' : '#FFA27F' }}>{entry.sld}</td>
                      <td style={{ backgroundColor: entry.roadTaxExpiry ? 'lightgreen' : '#FFA27F' }}>{entry.roadTax}</td>
                      <td style={{ backgroundColor: entry.retroTapeExpiry ? 'lightgreen' : '#FFA27F' }}>{entry.retroTape}</td>
                      <td style={{ backgroundColor: entry.permitExpiry ? 'lightgreen' : '#FFA27F' }}>{entry.permit}</td>
                      <td style={{ backgroundColor: entry.cSignExpiry ? 'lightgreen' : '#FFA27F' }}>{entry.cSign}</td>
                      <td>
                        <Button onClick={() => handleFetchData(index)}><FcSynchronize /></Button>
                        <Button variant="primary" onClick={handleUpdate}><FaEdit /></Button>
                        <Button variant="danger" onClick={() => fetchDeleteData(index)}><FaTrash /></Button>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </Table>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default VahanDoc;

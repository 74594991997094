import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Modal, Button, Table, Form } from 'react-bootstrap';
import './SearchForm.css'; // For CSS styling
import AuthUser from './Authuser';
import { FaEdit } from "react-icons/fa";
import { FaTrash } from "react-icons/fa";
import { format } from 'date-fns';
import { FcCheckmark } from "react-icons/fc";





const SearchForm = () => {
  const { token, refreshToken, http, logout } = AuthUser();
  const [searchBy, setSearchBy] = useState('date');
  const [inputValue, setInputValue] = useState('');
  const [vehicleNo, setVehicleNo] = useState('');
  const [month, setMonth] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [responseData, setResponseData] = useState([]);
  const [editIndex, setEditIndex] = useState(-1);
  const [editedItem, setEditedItem] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const navigate = useNavigate(); // Hook for navigation

  // Function to fetch data based on search criteria
  const fetchData = async () => {
    let data;
    if (searchBy === 'vehicleNo') {
      data = {
        searchBy,
        vehicleNo,
        month
      };
    } else {
      data = {
        searchBy,
        value: inputValue
      };
    }
    try {
      const response = await http.post('api_sales_filter', data);
      setResponseData(response.data); // Store response data
      setShowModal(true); // Show modal with data
    } catch (error) {
      console.error('There was an error fetching the data!', error);
      if (error.response && error.response.status === 401) {
        // Token expired or unauthorized
        logout();
        navigate('/login'); // Redirect to login screen
      }
    }
  };

  // Handle form submission
  const handleSubmit = (event) => {
    event.preventDefault();
    fetchData();
  };

  // Edit row handler
  const handleEdit = (index) => {
    setEditIndex(index);
    setEditedItem(responseData[index]);
    setShowEditModal(true);
  };

  const formatDate = (dateString) => {
    // Handle potential invalid date formats gracefully
    if (!dateString || !dateString.match(/^\d{2}\/\d{2}\/\d{4}$/)) {
      console.warn("Invalid date format. Expected DD/MM/YYYY.");
      return editedItem.date; // Return the original s_date to avoid overwriting with an invalid value
    }
  
    const [day, month, year] = dateString.split('/');
    const date = new Date(`${year}-${month}-${day}`);
  
    // Validate the parsed date object
    if (isNaN(date.getTime())) {
      console.warn("Invalid date object after parsing. Please check input format.");
      return editedItem.date; // Return the original s_date to avoid issues
    }
  
    // Return the formatted date string
    return format(date, 'yyyy-MM-dd'); // Assuming 'format' is a defined function for date formatting
  };
  // Save edited item handler
  const handleSaveEdit = async () => {
    try {
      // Call formatDate before updating editedItem.s_date
      editedItem.s_date = formatDate(editedItem.date);
  
      const response = await http.put(`api_sales_update`, editedItem);
      console.log('Item updated:', response.data);
      console.log("s_date", editedItem.date);
      setShowEditModal(false);
      

  
      // Re-fetch updated data or update locally depending on your needs
    } catch (error) {
      console.error('Error updating item:', error);
    }
  };

  // Delete row handler
  const handleDelete = (index) => {
    setEditIndex(index);
    setShowDeleteModal(true);
  };

  // Confirm delete handler
  const handleConfirmDelete = async () => {
    try {
      const response = await http.delete(`api_sales_delete/${responseData[editIndex].id}`);
      console.log('Item deleted:', response.data);
      setShowDeleteModal(false);
      // Refetch data or update locally
    } catch (error) {
      console.error('Error deleting item:', error);
    }
  };

  // Cancel delete handler
  const handleCancelDelete = () => {
    setShowDeleteModal(false);
  };

  // Handle input change for edit form
  const handleEditInputChange = (event) => {
    const { name, value } = event.target;
    setEditedItem({ ...editedItem, [name]: value });
  };

  // Close edit modal
  const handleCloseEditModal = () => {
    setShowEditModal(false);
    setEditedItem(null);
    setEditIndex(-1);
  };

  // Render edit form
  const renderEditForm = () => {
    if (editedItem) {
      return (
        <Modal show={showEditModal} onHide={handleCloseEditModal}>
          <Modal.Header closeButton>
            <Modal.Title>Edit Item</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group controlId="editForm">
                <Table>
                    <thead></thead>
                    <tbody>

                        <tr>
                            <th><Form.Label>vehicleNo</Form.Label></th>
                            <th> <Form.Control
                                type="text"
                                name="vehicleNo"
                                value={editedItem.vehicleNo}
                                onChange={handleEditInputChange}
                                />
                            </th>
                        </tr>
                        <tr>
                            <th><Form.Label>Collention</Form.Label></th>
                            <th> <Form.Control
                                type="text"
                                name="collection"
                                value={editedItem.collection}
                                onChange={handleEditInputChange}
                                />
                            </th>
                        </tr>
                        <tr>
                            <th><Form.Label>diesel</Form.Label></th>
                            <th> <Form.Control
                                type="text"
                                name="diesel"
                                value={editedItem.diesel}
                                onChange={handleEditInputChange}
                                />
                            </th>
                        </tr>
                        <tr>
                            <th><Form.Label>dieselAmount</Form.Label></th>
                            <th> <Form.Control
                                type="text"
                                name="dieselAmount"
                                value={editedItem.dieselAmount}
                                onChange={handleEditInputChange}
                                />
                            </th>
                        </tr>
                        <tr>
                            <th><Form.Label>expense</Form.Label></th>
                            <th> <Form.Control
                                type="text"
                                name="expense"
                                value={editedItem.expense}
                                onChange={handleEditInputChange}
                                />
                            </th>
                        </tr>
                        <tr>
                            <th><Form.Label>expAmount</Form.Label></th>
                            <th> <Form.Control
                                type="text"
                                name="expAmount"
                                value={editedItem.expAmount}
                                onChange={handleEditInputChange}
                                />
                            </th>
                        </tr>
                        <tr>
                            <th><Form.Label>onlineSale</Form.Label></th>
                            <th> <Form.Control
                                type="text"
                                name="onlineSale"
                                value={editedItem.onlineSale}
                                onChange={handleEditInputChange}
                                />
                            </th>
                        </tr>
                        <tr>
                            <th><Form.Label>noOfPassenger</Form.Label></th>
                            <th> <Form.Control
                                type="text"
                                name="noOfPassenger"
                                value={editedItem.noOfPassenger}
                                onChange={handleEditInputChange}
                                />
                            </th>
                        </tr>
                        <tr>
                            <th><Form.Label>driver</Form.Label></th>
                            <th> <Form.Control
                                type="text"
                                name="driver"
                                value={editedItem.driver}
                                onChange={handleEditInputChange}
                                />
                            </th>
                        </tr>
                        <tr>
                            
                            <th style={{visibility:"hidden"}} > <Form.Control
                                type="text"
                                name="id"
                                value={editedItem.id}
                                onChange={handleEditInputChange}
                                />
                            </th>
                            <th style={{visibility:"hidden"}} > <Form.Control
                                type="text"
                                name="s_date"
                                value={editedItem.date}
                                onChange={handleEditInputChange}
                                />
                            </th>
                        </tr>
                       
                    </tbody>
                </Table>
                
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseEditModal}>
              Close
            </Button>
            <Button variant="primary" onClick={handleSaveEdit}>
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>
      );
    }
    return null;
  };

  // Render delete confirmation modal
  const renderDeleteModal = () => (
    <Modal show={showDeleteModal} onHide={handleCancelDelete}>
      <Modal.Header closeButton>
        <Modal.Title>Confirm Delete</Modal.Title>
      </Modal.Header>
      <Modal.Body>Are you sure you want to delete this item?</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleCancelDelete}>
        <FaEdit />
        </Button>
        <Button variant="danger" onClick={handleConfirmDelete}>
        <FaTrash />
        </Button>
      </Modal.Footer>
    </Modal>
  );

    // Render delete confirmation modal
   

  return (
    <>
      <form onSubmit={handleSubmit} className="search-form">
        <label>
          Search by:
          <select value={searchBy} onChange={(e) => setSearchBy(e.target.value)}>
            <option value="date">Date</option>
            <option value="vehicleNo">Vehicle No</option>
            <option value="month">Month</option>
          </select>
        </label>

        {searchBy === 'date' && (
          <label>
            Date:
            <input type="date" value={inputValue} onChange={(e) => setInputValue(e.target.value)} />
          </label>
        )}

        {searchBy === 'vehicleNo' && (
          <>
            <label>
              Vehicle No:
              <input type="text" value={vehicleNo} onChange={(e) => setVehicleNo(e.target.value.toLocaleUpperCase())} />
            </label>
            <label>
              Month:
              <input type="month" value={month} onChange={(e) => setMonth(e.target.value)} />
            </label>
          </>
        )}

        {searchBy === 'month' && (
          <label>
            Month:
            <input type="month" value={inputValue} onChange={(e) => setInputValue(e.target.value)} />
          </label>
        )}

        <button type="submit">Search</button>
      </form>

      <Modal show={showModal} onHide={() => setShowModal(false)} size="xl" fullscreen="true">
        <Modal.Header closeButton>
          <Modal.Title>Filtered Data</Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-body">
          <div className="table-responsive">
            <Table striped bordered hover className="table">
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Vehicle No</th>
                  <th>Driver</th>
                  <th>Collection</th>
                  <th>Diesel</th>
                  <th>Diesel Amount</th>
                  <th>Other Exp.</th>
                  <th>Exp Amount</th>
                  <th>Online Sale</th>
                  <th>No. of Pass</th>
                
                  <th>Actions</th> 
                </tr>
              </thead>
              <tbody>
                {responseData.map((item, index) => (
                  <tr key={index}>
                    <td>{item.date}</td>
                    <td>{item.vehicleNo}</td>
                    <td>{item.driver}</td>
                    <td>{item.collection}</td>
                    <td>{item.diesel}</td>
                    <td>{item.dieselAmount}</td>
                    <td>{item.expense}</td>
                    <td>{item.expAmount}</td>
                    <td>{item.onlineSale}</td>
                    <td>{item.noOfPassenger}</td>
                    <td>
                      <Button variant="info" onClick={() => handleEdit(index)}>
                      <FaEdit/>
                      </Button>{' '}
                      <Button variant="danger" onClick={() => handleDelete(index)}>
                      <FaTrash />
                      </Button>
                    </td>
                    <td style={{visibility:"hidden"}}>{item.id}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {renderEditForm()}
      {renderDeleteModal()}
    </>
  );
};

export default SearchForm;

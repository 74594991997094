import { Route, Routes,useLocation  } from "react-router-dom";
import Home from "./components/Home";
import SalesReport from "./components/SalesReport";
import Login from "./components/Login";
import Dashboard from "./components/Dashboard";
import Header from "./components/Header";
import Booking from "./components/Booking";
import BusDetails from "./components/BusDetails";
import BusRegistration from "./components/BusRegistration";
import BusRoute from "./components/BusRoute";
import BusServiciingHistory from "./components/BusServiciingHistory";
import EmployeeRegistration from "./components/EmployeeRegistration";
import ServicingItem from "./components/ServicingItem";
import TyreEntry from "./components/TyreEntry";
import TyreJourney from "./components/TyreJourney";
import Test from "./components/Test";
import VahanDoc from "./components/VahanDoc";
import NewBooking from "./components/NewBooking";
import VehicleServicingHistory from "./components/VehicleServicingHistory";
import VehicleServicing from "./components/VehicleServicing";
import OwnerSignUp from "./components/OwnerSignUp";
import FeedbackForm from "./components/FeedbackForm";
import Profile from "./components/Profile";
import SearchForm from "./components/SearchComposent";
import LocationDashboard from "./components/GpsDashboard";

function App() {
  const location = useLocation();
  const showHeader = location.pathname !== '/' && location.pathname !== '/signUp';
  return (
  
    <div className="App">
        {showHeader && <Header />}
        <Routes>
          <Route path="/" element={<Login/>}/>
          <Route path="/signUp" element={<OwnerSignUp/>}/>
          <Route path='/home' element={<Home/>}/>
          <Route path ="/salesReport" element={<SalesReport/>}/>
          <Route path ="/ServicingItem" element={<ServicingItem/>}/>
          <Route path ="/vehicleServicing" element={<VehicleServicing/>}/>
          <Route path ="/BusServicing" element={<BusServiciingHistory/>}/>
          <Route path ="/TyreEntry" element={<TyreEntry/>}/>
          <Route path ="/TyreJourney" element={<TyreJourney/>}/>
          <Route path ="/BusEntry" element={<BusRegistration/>}/>
          <Route path ="/BusDetails" element={<BusDetails/>}/>
          <Route path ="/BusRoute" element={<BusRoute/>}/>
          <Route path ="/Booking" element={<Booking/>}/>
          <Route path="/EmployeeRegistration" element={<EmployeeRegistration/>}/>
          <Route path="/dashboard" element={<Dashboard/>}/>
          <Route path="/test" element={<Test/>}/>
         
          <Route path="/vahanDoc" element={<VahanDoc/>}/>
          <Route path="/newBooking" element={<NewBooking/>}/>
          <Route path="/vehicleServicingHistory" element={<VehicleServicingHistory/>}/>
          <Route path="/feedback" element={<FeedbackForm/>}/>
          <Route path="/profile" element={<Profile/>}/>
          <Route path="/search" element={<SearchForm/>}/>
          <Route path="/gpsDashboard" element={<LocationDashboard/>}/>

        </Routes>
      
    </div> 
  );
}

export default App;

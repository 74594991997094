import React, { useState } from 'react';
import '../components/OwnerSignUp.css';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { endPoint } from './EndPoint';
import Loader from './Loader';
import { Modal, Button } from 'react-bootstrap';

function OwnerSignUp() {
  const [formData, setFormData] = useState({
    companyName: '',
    contactNo: '',
    email: '',
    password: '',
    cAddress: ''
  });

  const [loading, setLoading] = useState(false);
  const [username, setUsername] = useState('');
  const [userPass, setUserPassword] = useState('');
  const [showModal, setShowModal] = useState(false);

  const handleClose = () => setShowModal(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value.toUpperCase() });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const res = await axios.post(endPoint + '/sign_up', formData);
      console.log(res);
      setUsername(res.data.userId);
      setUserPassword(res.data.password);
      setShowModal(true);
    } catch (error) {
      console.log("error", error);
      
    }
    setLoading(false);
  };

  return (
    <div className="OwnerSignUp">
      {loading && <Loader />}
      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Account Is Created !!</Modal.Title>
        </Modal.Header>
        <Modal.Body>username is {username} and Password is {userPass}</Modal.Body>
        <Modal.Footer>
          <p>Write down these credentials for further uses</p>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <h2>Signup</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <input
            type="text"
            name="companyName"
            value={formData.companyName}
            onChange={handleChange}
            required
            placeholder='Company Name'
          />
        </div>
        <div className="form-group">
          <input
            type="text"
            name="contactNo"
            value={formData.contactNo}
            onChange={handleChange}
            required
            placeholder='Contact No.'
          />
        </div>
        <div className="form-group">
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
            placeholder='Email'
          />
        </div>
        <div className="form-group">
          <input
            type="password"
            name="password"
            value={formData.password}
            onChange={handleChange}
            required
            placeholder='Password'
          />
        </div>
        <div className="form-group">
          <textarea
            name="cAddress"
            value={formData.cAddress}
            onChange={handleChange}
            required
            placeholder='Company Address'
          />
        </div>
        <button type="submit">Signup</button>
      </form>
      <br />
      <p><Link to="/">If You have account ! Click here to login</Link></p>
    </div>
  );
}

export default OwnerSignUp;

import React from 'react'

function BusServiciingHistory() {
  return (
    <div>
      BusServiciingHistory
    </div>
  )
}

export default BusServiciingHistory

import React, {useEffect, useState } from 'react';
import axios from 'axios';
import Datepicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import './Sales.css';
import {Row,Col,Table,Button,Modal} from 'react-bootstrap';
import { FaEdit } from "react-icons/fa";
import { FaTrash } from "react-icons/fa";
import AuthUser from './Authuser';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import SearchForm from './SearchComposent';

const SalesReport = () => {
  const { token, refreshToken, http, logout } = AuthUser();
  const navigate = useNavigate();
  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
    const day = String(today.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
};



  useEffect(() => {
      if (!token) {
          // Redirect to login if no token
          navigate('/userlogin');
      }
  }, [token, navigate]);
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [isData,setIsData] =useState(true)

  const [vehicleNo,setVehicleNo] = useState('');
  const [collection,setCollection] = useState('');
  const [diesel,setDiesel] = useState('');
  const [dieselAmount,setDieselAmount] = useState('');
  const [expense,setOther] = useState('');
  const [expAmount,setExpense] = useState('');
  const [driver,setDriver] = useState('');
  const [onlineSale,setOnlineSale] = useState('');
  const [noOfPassenger,setNoOfPassenger] = useState('');
  const [s_date,setSDate] = useState(getCurrentDate());
  const [id,setId] = useState('');

  const [loading,setLoading] =useState(false)
  const [res,setRes] =useState(false)

  const [dataload,setDataLoad] =useState(true)
  const [isDisabled, setIsDisabled] = useState(true);



  const fetchData = async () => {
    try {
      const response = await http.get('/api_sales');
    
      const resData = await response.data;
      setData(resData);
      setIsData(false);
      console.log(resData)
      console.log(resData)
    } catch (error) {
      setError(error);
      setIsData(false);
      navigate('/')
    }
  };
  useEffect(() => {
    // Define the async function to fetch data
    

    // Call the fetch function
    fetchData();
  }, []);
 

  useEffect(() => {
    if (res) {
      const timer = setTimeout(() => {
        setRes('');
      }, 5000); // Clear the result message after 5 seconds

      return () => clearTimeout(timer); // Cleanup the timer on component unmount
    }
  }, [res]);
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true)
    try {
      
      const response = await http.post('/api_sales_entry', {
        vehicleNo,
        collection,
        diesel,
        dieselAmount,
        expAmount,
        expense,
        driver,
        onlineSale,
        noOfPassenger,
        s_date:s_date
      });
      
      console.log(response.data);
      setLoading(false)
      setCollection('')
      setDiesel('')
      setDieselAmount('')
      setDriver('')
      setExpense('')
      setNoOfPassenger('')
      setOnlineSale('')
      setVehicleNo('')
      setOther('')
      setRes('data insert successfully!!')
      fetchData()
      // Assuming the API returns some data upon successful submission
      // Optionally, you can redirect the user or show a success message here

    } catch (error) {
      console.error('Error submitting sales entry:', error);
      setLoading(false);
      setRes('Error submitting data');
      // Handle error, show error message to the user, etc.
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    setLoading(true)
    
    try {
      
      const response = await http.put('/api_sales_update', {
        id,
        vehicleNo,
        collection,
        diesel,
        dieselAmount,
        expAmount,
        expense,
        driver,
        onlineSale,
        noOfPassenger,
        s_date
      });
      console.log(response.data);
      setLoading(false)
      setCollection('')
      setDiesel('')
      setDieselAmount('')
      setDriver('')
      setExpense('')
      setNoOfPassenger('')
      setOnlineSale('')
      setVehicleNo('')
      setOther('')
      setRes("data updated")
      fetchData()
      // Assuming the API returns some data upon successful submission
      // Optionally, you can redirect the user or show a success message here

    } catch (error) {
      console.error('Error submitting sales entry:', error);
      setLoading(false);
      setRes('Error submitting data');
      // Handle error, show error message to the user, etc.
    }
  };


  const handleDelete = async (index) => {
    setLoading(true);
    const salesDataToDelete = data[index]; // Use a more descriptive name
    
    try {
      const response = await http.delete(`/api_sales_delete/${salesDataToDelete.id}`);
  
      console.log('Sales data deleted successfully:', response.data);
      setRes('Collection data deleted');
      fetchData();
      // Optionally handle successful deletion (e.g., update UI, redirect)
    } catch (error) {
      console.error('Error deleting sales data:', error);
  
      // Handle specific error types (e.g., network errors)
      if (error.response) {
        // Server responded with a status code that falls outside the range of 2xx
        console.error('Server error:', error.response.data);
        setRes('Error deleting data (server error)');
      } else if (error.request) {
        // The request was made but no response was received
        console.error('Network error:', error.request);
        setRes('Error deleting data (network error)');
      } else {
        // Something happened in setting up the request that triggered an Error
        console.error('Error:', error.message);
        setRes('Error deleting data (unknown error)');
      }
    } finally {
      setLoading(false);
    }
  };
  
  
  const formatDate = (dateString) => {
    const [day, month, year] = dateString.split('/');
    const date = new Date(`${year}-${month}-${day}`);
    return format(date, 'yyyy-MM-dd');
  };
  const handleFetchData = (index) => {
    setIsDisabled(false);
    const selectedData = data[index]; // Get the data of the selected row
    setSDate(formatDate(selectedData.date) );
    setVehicleNo(selectedData.vehicleNo);
    setCollection(selectedData.collection);
    setDiesel(selectedData.diesel);
    setDieselAmount(selectedData.dieselAmount);
    setOther(selectedData.expense);
    setExpense(selectedData.expAmount);
    setDriver(selectedData.driver);
    setOnlineSale(selectedData.onlineSale);
    setNoOfPassenger(selectedData.noOfPassenger);
    setId(selectedData.id);
    
    console.log(selectedData.id)
  };

  
  


  if (isData) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <div className="container-fluid" >
        
        <Row className="justify-content-md-center">
          <Col md='3' >
            <div className='container' >
              <h3>Sales Entry</h3>
              <form onSubmit={handleSubmit}>
                <table className='table responsive' >
                  <thead></thead>
                  <tbody className='salesEntry' >
                    <tr>
                      <td>Entry Date:</td>
                    
                      <td>
                        <input type="date"   name='s_date' value={s_date} onChange={e => setSDate(e.target.value)}/>
                      
                      </td>
                    
                    </tr>
                    <tr>
                      <td>Vehicle No:</td>
                      <td>
                        <input
                          type="text"
                          name="vehicleNo"
                          pattern="[A-Z0-9]+"
                          value={vehicleNo}
                          onChange={(e) => setVehicleNo(e.target.value.toUpperCase())}
                          required
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Collections:</td>
                      <td>
                        <input
                          type="number"
                          name="collection"
                          pattern="[0-9]"
                          value={collection}
                          onChange={(e) => setCollection(e.target.value)}
                          required
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Diesel (liters):</td>
                      <td>
                        <input
                          type="number"
                          name="diesel"
                          pattern='[0-9]'
                          value={diesel}
                          onChange={(e) => setDiesel(e.target.value)}
                          required
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Diesel Price:</td>
                      <td>
                        <input
                          type="number"
                          name="dieselAmount"
                          pattern='[0-9]'
                          value={dieselAmount}
                          onChange={(e) => setDieselAmount(e.target.value)}
                          required
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Other expense:</td>
                      <td>
                        <input
                          type="text"
                          name="expense"
                          
                          value={expense}
                          onChange={(e) => setOther(e.target.value.toUpperCase())}
                          required
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Expense Amount:</td>
                      <td>
                        <input
                          type="number"
                          name="expAmount"
                          pattern='[0-9]'
                          value={expAmount}
                          onChange={(e) => setExpense(e.target.value)}
                          required
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Driver id:</td>
                      <td>
                        <input
                          type="text"
                          name="driver"
                          
                          value={driver}
                          onChange={(e) => setDriver(e.target.value.toUpperCase())}
                          required
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Online Sale:</td>
                      <td>
                        <input
                          type="number"
                          name="onlineSale"
                          pattern='[0-9]'
                          value={onlineSale}
                          onChange={(e) => setOnlineSale(e.target.value)}
                          required
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Number of Passengers:</td>
                      <td>
                        <input
                          type="number"
                          name="noOfPassenger"
                          pattern='[0-9]'
                          value={noOfPassenger}
                          onChange={(e) => setNoOfPassenger(e.target.value)}
                          required
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
                <button type="submit" style={{marginTop:"2px"}}>Save</button>
                <button onClick={handleUpdate} style={{marginLeft:"30px",marginTop:"2px",backgroundColor:"orange"}}>Update</button>
              </form>
              {loading ? <p>Data sending...</p> : <h1></h1>}
              {res}

            </div>
          
          </Col>
          <Col md='9'>
            <div className="searchCol">
              <SearchForm/>
            </div>
            <Table responsive striped bordered hover>
              <thead>
                <tr className="table-header">
                  <th>Date</th>
                  <th>Vehicle No</th>
                  <th>Collection</th>
                  <th>Diesel</th>
                  <th>Diesel Amount</th>
                  <th>Other Exp.</th>
                  <th>Exp Amount</th>
                  <th>Online Sale</th>
                  <th>No. of Pass</th>
                  <th>Driver Id</th>
                  <th>Action</th>
                  
                </tr>
              </thead>
              <tbody>
                {data.map((item, index) => (
                  <tr key={index} className="table-row">
                    <td>{item.date}</td>
                    <td>{item.vehicleNo}</td>
                    <td>{item.collection}</td>
                    <td>{item.diesel}</td>
                    <td>{item.dieselAmount}</td>
                    <td>{item.expense}</td>
                    <td>{item.expAmount}</td>
                    <td>{item.onlineSale}</td>
                    <td>{item.noOfPassenger}</td>
                    <td>{item.driver}</td>
                    
                    <td>
                    <Button onClick={() => handleFetchData(index)}><FaEdit /></Button>
                    {/* Delete button */}
                    <Button onClick={() => handleDelete(index)} ><FaTrash /></Button>
                    </td>
                    <td style={{visibility:"hidden"}}>{item.id}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
        </Row>
    </div>
  );
};

export default SalesReport;

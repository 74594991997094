import React, { useState,useEffect } from 'react';
import axios from 'axios';
import { FcEmptyTrash } from 'react-icons/fc';
import { IoIosAddCircleOutline } from 'react-icons/io';
import { BiRupee } from 'react-icons/bi';
import './servicingHistory.css';
import { BiTrash } from "react-icons/bi";
import { endPoint } from './EndPoint';
import AuthUser from './Authuser';
import { useNavigate } from 'react-router-dom';


function VehicleServicing() {

  const { token, refreshToken, http, logout } = AuthUser();
  const navigate = useNavigate();

  useEffect(() => {
      if (!token) {
          // Redirect to login if no token
          navigate('/userlogin');
      }
  }, [token, navigate]);

  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
    const day = String(today.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };
  const [vehicleNo, setVehicleNo] = useState('');
  const [due, setDue] = useState(0);
  const [date, setDate] = useState(getCurrentDate());
  const [payment,setPayment]=useState(0);
  const [rows, setRows] = useState([{ name: '', qty: '', unit: '', price: '' }]);

  const handleChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...rows];
    list[index][name] = value;
    setRows(list);
  };

  const handleAddRow = () => {
    setRows([...rows, { name: '', qty: '', unit: '', brand:'',price: '' }]);
  };

  const handleRemoveRow = (index) => {
    const list = [...rows];
    list.splice(index, 1);
    setRows(list);
  };

  const getTotal = () => {
    return rows.reduce((total, item) => {
      return total + Number(item.price);
    }, 0);
  };

  const saveData = async () => {

    try {
      const totalAmount = rows.reduce((total, item) => {
        return total + Number(item.price);
       
      }, 0);
      setDue(totalAmount-payment) 
      const payload = {
        vehicleNo,
        date,
        totalAmount,
        rows,
        payment,
        due,
      };
      const response = await http.post('/api_vehicle_servicing', payload);
      console.log('Data saved successfully', response.data);
    } catch (error) {
      console.error('Error saving data', error);
    }
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-3">
          <label>
            vehicle No.
            <input
              type="text"
              name="bus_no"
              className="form-control"
              onChange={(e) => setVehicleNo(e.target.value.toUpperCase())}
            />
          </label>
        </div>
        <div className="col-md-3">
          <label>
            Date:-
            <input
              type="date"
              name="date"
              value={date}
              className="form-control"
              id="date"
              onChange={(e) => setDate(e.target.value)}
            />
          </label>
        </div>
        <div className="col-md-3" style={{ color: 'red', fontWeight: 'bold' }}>
          Total Amount: <BiRupee />
          {getTotal()}/-
        </div>
        <div className="col-md-3">
          <label>
            payment
            <input
              type="number"
              name="pay"
              className="form-control"
              id="date"
              value={payment}
              onChange={(e) => setPayment(e.target.value)}
            />
          </label>

        </div>
        <div className="col-md-12" id="itemAdd">
          {rows.map((item, index) => (
            <div key={index} className="row" id={index}>
              <div className="col-md-4">
                <input
                  type="text"
                  name="name"
                  className="form-control"
                  value={item.name}
                  onChange={(e) => handleChange(e, index)}
                  placeholder="Name"
                />
              </div>
              <div className="col-md-1">
                <input
                  type="number"
                  name="qty"
                  className="form-control"
                  value={item.qty}
                  onChange={(e) => handleChange(e, index)}
                  placeholder="Quantity"
                />
              </div>
              <div className="col-md-1">
                <input
                  type="text"
                  name="unit"
                  className="form-control"
                  value={item.unit}
                  onChange={(e) => handleChange(e, index)}
                  placeholder="Unit"
                />
              </div>
              <div className="col-md-1">
                <input
                  type="text"
                  name="brand"
                  className="form-control"
                  value={item.brand}
                  onChange={(e) => handleChange(e, index)}
                  placeholder="Brand"
                />
              </div>
              <div className="col-md-2">
                <input
                  type="number"
                  name="price"
                  className="form-control"
                  value={item.price}
                  onChange={(e) => handleChange(e, index)}
                  placeholder="Price"
                />
              </div>
              <div className="col-md-2" style={{textAlign:'center'}}>
                <BiTrash onClick={() => handleRemoveRow(index)} style={{ fontSize: '30px' }} />
                |
                <IoIosAddCircleOutline onClick={handleAddRow} style={{ fontSize: '30px' }} />
              </div>
            </div>
          ))}
          <div className="savebtn">
            <button className="btn btn-success" onClick={saveData}>
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default VehicleServicing;

import React from 'react'



function BusRegistration() {
  return (
    <div>
      Comming Soon
    </div>
  )
}

export default BusRegistration

import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { endPoint } from './EndPoint';
import { Container, Table, Button, Modal, Form, Row, Col } from 'react-bootstrap';
import { format } from 'date-fns';
import AuthUser from './Authuser';
import { useNavigate } from 'react-router-dom';

const VehicleServicingHistory = () => {
  const { token, refreshToken, http, logout } = AuthUser();
  const navigate = useNavigate();

  useEffect(() => {
    if (!token) {
      // Redirect to login if no token
      navigate('/userlogin');
    }
  }, [token, navigate]);

  const [data, setData] = useState([]);
  const [vehicleNo, setVehicleNo] = useState('');
  const [sDate, setSdate] = useState('');
  const [fData, setFdata] = useState([]);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const fetchData = async () => {
    try {
      const response = await http.get('/api_vehicle_servicing');
      const resData = await response.data;
      setData(resData);
    } catch (error) {
      console.error('Fetch data error: ', error);
      if (error.response && error.response.status === 401) {
        // Token expired or unauthorized
        logout();
        navigate('/login'); // Redirect to login screen
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const formatDate = (dateString) => {
    const [day, month, year] = dateString.split('/');
    const date = new Date(`${year}-${month}-${day}`);
    return format(date, 'yyyy-MM-dd');
  };

  const getFilterData = async (index) => {
    const selectedData = data[index];
    const vehicleNo = selectedData.vehicleNo;
    const sDate = formatDate(selectedData.date);

    setVehicleNo(vehicleNo);
    setSdate(sDate);

    try {
      const response = await http.post('/api_servicing_filter', {
        vehicleNo: vehicleNo,
        date: sDate,
      });
      setFdata(response.data);
      handleShow();
    } catch (error) {
      console.error('Fetch filter data error: ', error);
      if (error.response && error.response.status === 401) {
        // Token expired or unauthorized
        logout();
        navigate('/login'); // Redirect to login screen
      }
    }
  };

  const handleSearch = async (e) => {
    e.preventDefault();

    try {
      const response = await http.post('/api_vehicle_servicing_filter', {
        vehicleNo: vehicleNo || null,
        date: sDate || null,
      });

      const resData = await response.data;
      setData(resData);
    } catch (error) {
      console.error('Search data error: ', error);
      if (error.response && error.response.status === 401) {
        // Token expired or unauthorized
        logout();
        navigate('/login'); // Redirect to login screen
      }
    }
  };

  return (
    <Container>
      <Form onSubmit={handleSearch} className="mb-4">
        <Row>
          <Col md={4}>
            <Form.Group controlId="formVehicleNo">
              <Form.Label>Vehicle No.</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Vehicle No."
                value={vehicleNo}
                onChange={(e) => setVehicleNo(e.target.value)}
              />
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Group controlId="formDate">
              <Form.Label>Servicing Date</Form.Label>
              <Form.Control
                type="date"
                value={sDate}
                onChange={(e) => setSdate(e.target.value)}
              />
            </Form.Group>
          </Col>
          <Col md={4} className="d-flex align-items-end">
            <Button variant="primary" type="submit">
              Search
            </Button>
          </Col>
        </Row>
      </Form>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Modal heading</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table>
            <thead>
              <tr>
                <th>Item</th>
                <th>Qty</th>
                <th>Unit</th>
                <th>Price</th>
                <th>Brand</th>
              </tr>
            </thead>
            <tbody>
              {fData.map((list, index) => (
                <tr key={index}>
                  <td>{list.item}</td>
                  <td>{list.quantity}</td>
                  <td>{list.unit}</td>
                  <td>{list.price}</td>
                  <td>{list.brand}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>

      <Table>
        <thead>
          <tr>
            <th>Date</th>
            <th>Vehicle No.</th>
            <th>Bill</th>
            <th>Payment</th>
            <th>Due</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {data.length > 0 ? (
            data.map((item, index) => (
              <tr key={index}>
                <td>{item.date}</td>
                <td>{item.vehicleNo}</td>
                <td>{item.bill}</td>
                <td>{item.payment}</td>
                <td>{item.due}</td>
                <td>
                  <Button onClick={() => getFilterData(index)}>Details</Button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="6" className="text-center">No data available</td>
            </tr>
          )}
        </tbody>
      </Table>
    </Container>
  );
};

export default VehicleServicingHistory;

import React, { useState,useEffect } from 'react';
import AuthUser from './Authuser';
import { useNavigate } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import Loader from './Loader';

const FeedbackForm = () => {
    const { token, refreshToken, http, logout } = AuthUser();
    const navigate = useNavigate();
    const [isLoader,setIsloader]=useState(false)
    useEffect(() => {
        if (!token) {
            // Redirect to login if no token
            navigate('/userlogin');
        }
    }, [token, navigate]);

    const [feedback, setFeedback] = useState('');
    const [submitted, setSubmitted] = useState(false);

    const handleSubmit = async(e) => {
        e.preventDefault();
        setSubmitted(true);
        setIsloader(true)
        // Here you can handle the submission, e.g., send the feedback to a server
        console.log({ feedback });
        try{
            const res=await http.post('/feedback/'+feedback)
            console.log(res.data)
        }catch(error){
            console.log(error)
            

        }
    };

return (
    <div className="container-fluid" >
        <Container style={{textAlign:"center"}}>
            {isLoader? Loader :<p></p>}
            {submitted ? (
                <div>
                    <h2>Thank you for your feedback!</h2>
                    <p>We appreciate your input.</p>
                </div>
            ) : (
                <form onSubmit={handleSubmit}>
                   
                    <div>
                        
                        <textarea
                            value={feedback}
                            onChange={(e) => setFeedback(e.target.value)}
                            required
                            placeholder='feedback form'
                            
                        ></textarea>
                      
                    </div>
                    <button type="submit">Submit</button>
                </form>
            )}
        </Container>

    </div>
)      
};

export default FeedbackForm;

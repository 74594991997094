import axios from 'axios'
import React, { useEffect,useState } from 'react'
import Authuser from './Authuser'
import './Dashboard.css'

function Dashboard() {
  const {token,http} =Authuser();
  const [userDetails,setUserDetails]=useState([]);
  const [testDetails,setTestDetails]=useState();


  
  useEffect(()=>{
    fetchUserDetail();
  },[]);
  const fetchUserDetail = () =>{
    http.get('profile/',yourtoken).then((res)=>{
      setUserDetails(res.data.owner_details); 
      console.log(res.data.owner_details);
    });
  }
  const yourtoken = {
    headers: 
        {
          Authorization: "Bearer " + token.access
        }
    }
  
  if(!userDetails){
    return(
      <div>loding....</div>
    )
  }
    
  return (
    
  <>
   
    
  </>
    
    
  )
}

export default Dashboard

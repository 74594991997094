import React, {useEffect, useState } from 'react';
import axios from 'axios';

import { Modal, Button } from 'react-bootstrap';
import 'react-datepicker/dist/react-datepicker.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FaCalendarAlt, FaStoreAltSlash } from "react-icons/fa";
import { endPoint } from './EndPoint';
import './Sales.css';


import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';

import { FaEdit } from "react-icons/fa";
import { FaTrash } from "react-icons/fa";
import { format } from 'date-fns';
import AuthUser from './Authuser';
import { useNavigate } from 'react-router-dom';

const TyreJourney = () => {
  const { token, refreshToken, http, logout } = AuthUser();
  const navigate = useNavigate();

  useEffect(() => {
      if (!token) {
          // Redirect to login if no token
          navigate('/userlogin');
      }
  }, [token, navigate]);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  
  const [error, setError] = useState(null);
  const [isData,setIsData] =useState(true)

  const [data, setData] = useState([]);
  const [date,setDate]=useState('2024-01-01');
  const [tyreNo,setTyreNo] = useState('');
  const [activity,setActivity] = useState('');
  const [expense,setExpense] = useState('');
  const [distance,setDistance] = useState('');
  
  const [assign,setAsign] = useState('');
  const [vehicleNo,setVehicleNo] = useState('');
  
  
  const [id,setId] = useState('');

  const [loading,setLoading] =useState(false)
  const [res,setRes] =useState(false)
  const [resData,setResData] =useState('')
  const [dataload,setDataLoad] =useState(true)
  
  
  const fetchData = async () => {
    try {
      const response = await http.get('/api_resoleTyre_list');
      
     
      const resData = await response.data;
      setData(resData);
      setIsData(false);
      console.log(resData)
      console.log(resData.data)
      
      
    } catch (error) {
      setError(error.response?.data?.error || 'An error occurred during data fetching.');
      navigate('/')
      setIsData(false);
      
    }
  };
  useEffect(() => {
    // Define the async function to fetch data
    

    // Call the fetch function
    fetchData();
  },[]);
 

  useEffect(() => {
    if (res) {
      const timer = setTimeout(() => {
        setRes(false);
      }, 5000); // Clear the result message after 5 seconds

      return () => clearTimeout(timer); // Cleanup the timer on component unmount
    }
  }, [res]);
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    console.log(assign)
    try {
      
      const response = await http.post('/api_resoleTyre_list', {
        date,
        tyreNo,
        activity,
        assign,
        expense,
        vehicleNo,
        distance,

      });
      console.log(response.data);
      setLoading(false)
      
      setTyreNo('')
      setActivity('')
      setExpense('')
      setAsign('')
      setVehicleNo('')
      setDistance('')
      
      fetchData()
      setRes(true)
      setResData('Data saved successfully !!')
      // Assuming the API returns some data upon successful submission
      // Optionally, you can redirect the user or show a success message here

    } catch (error) {
      console.error('Error submitting sales entry:', error);
      setLoading(false);
      setRes('Error submitting data');
      // Handle error, show error message to the user, etc.
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    setLoading(true)
   
    
    try {
      
      const response = await http.put('/api_resoleTyre_update/'+id, {
        id,
        date,
        tyreNo,
        activity,
        assign,
        expense,
        vehicleNo,
        distance,
        
      });
      console.log(response.data);
      setLoading(false)
      setTyreNo('')
      setActivity('')
      setExpense('')
      setAsign('')
      setVehicleNo('')
      setDistance('')
      fetchData()
      setRes(true)
      setResData('data updated successfully')
      // Assuming the API returns some data upon successful submission
      // Optionally, you can redirect the user or show a success message here

    } catch (error) {
      console.error('Error submitting sales entry:', error);
      setLoading(false);
      setRes('Error submitting data');
      // Handle error, show error message to the user, etc.
    }
  };

  const fetchDeleteTyre= (index) =>{
    handleShow()
    const selectedData = data[index];
    setTyreNo(selectedData.tyreNo)
    setId(selectedData.id)
    console.log(tyreNo)
    setRes(true)
    setResData('data deleted')
  }
  const handleDelete = async (index) => {
    setLoading(true);
  
    try {
      const response = await http.delete('/api_resoleTyre_delete/'+id);
  
      console.log('Sales data deleted successfully:', response.data);
      setLoading(false);
      
      fetchData()
      handleClose()
      setRes(true)
      setResData('data deleted')
      // Optionally handle successful deletion (e.g., update UI, redirect)
    } catch (error) {
      console.error('Error deleting sales data:', error);
  
      // Handle specific error types (e.g., network errors)
      if (error.response) {
        // Server responded with a status code that falls outside the range of 2xx
        console.error('Server error:', error.response.data);
        setRes(true)
        setResData('Retry')
      } else if (error.request) {
        // The request was made but no response was received
        console.error('Network error:', error.request);
        setRes(true)
        setResData('Error deleting data (network error)');
      } else {
        // Something happened in setting up the request that triggered an Error
        console.error('Error:', error.message);
        setRes(true)
        setResData('Error deleting data (unknown error)');
      }
  
      setLoading(false);
    }
  };
  const formatDate = (dateString) => {
    const [day, month, year] = dateString.split('/');
    const date = new Date(`${year}-${month}-${day}`);
    return format(date, 'yyyy-MM-dd');
  };


  const handleFetchData = (index) => {
    const selectedData = data[index]; // Get the data of the selected row
    setId(selectedData.id)
    setTyreNo(selectedData.tyreNo)
    setActivity(selectedData.activity)
    setAsign(selectedData.assign)
    setExpense(selectedData.expense)
    setVehicleNo(selectedData.vehicleNo)
    setDate(formatDate(selectedData.date))
    setDistance(selectedData.distance)
    console.log(selectedData.tyreNo,date,id)
   

  };



  


  if (isData) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <div className="container-fluid" >
      {error}
        <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Are you sure to delete?</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                   <h1>Tyre No:- {tyreNo}</h1>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Cancle
                    </Button>
                    <Button variant="danger" onClick={handleDelete}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
      
        <Row className="justify-content-md-center">
          <Col md='3' >
            <div className='container' >
              <h3>Resole tyre Entry</h3>
              <form onSubmit={handleSubmit}>
                <table>
                  <tbody className='salesEntry'>
                    <tr>
                      <td>Date:</td>
                    
                      <td>
                        <input type="date"  name='date' value={date} onChange={e => setDate(e.target.value)}/>
                      
                      </td>
                    
                    </tr>
                    <tr>
                      <td>Tyre No:</td>
                      <td>
                        <input
                          type="text"
                          name="TyreNo"
                          pattern="[A-Z0-9]+"
                          value={tyreNo}
                          onChange={(e) => setTyreNo(e.target.value.toUpperCase())}
                          required
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Activity:</td>
                      <td>
                        
                         <select name="activity" id="" value={activity} onChange={(e) => setActivity(e.target.value)}>
                          <option value="">select one</option>
                          <option value="BUS">BUS</option>
                          <option value="TRUCK">TRUCK</option>
                          <option value="RESOLE">RESOLE</option>
                          <option value="OTHER">OTHER</option>

                       </select>
                      </td>
                    </tr>
                    <tr>
                      <td>Assign:</td>
                      <td>
                        <input
                          type="text"
                          name="assign"
                        
                          value={assign}
                          onChange={(e) => setAsign(e.target.value.toUpperCase())}
                          placeholder='recieve/send to'
                          required
                        />
                      </td>
                    </tr>
                    
                    <tr>
                      <td>Expense:</td>
                      <td>
                        <input
                          type="number"
                          name="expense"
                          pattern='[0-9]'
                          value={expense}
                          onChange={(e) => setExpense(e.target.value)}
                          required
                        />
                      </td>
                    </tr>
                    
                    
                    <tr>
                      <td>address:</td>
                      <td>
                        <input
                          type="text"
                          name="vehicleNo"
                          
                          value={vehicleNo}
                          onChange={(e) => setVehicleNo(e.target.value.toUpperCase())}
                          required
                          placeholder='vehicle No / store address'
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Distance:</td>
                      <td>
                        <input
                          type="text"
                          name="distance"
                          pattern='[A-Z0-9]+'
                          value={distance}
                          onChange={(e) => setDistance(e.target.value.toUpperCase())}
                          required
                          placeholder='meter reading'
                        />
                      </td>
                    </tr>

                   
                  </tbody>
                </table>
                <button type="submit" style={{marginTop:"20px"}}>Save</button>
                <button onClick={handleUpdate} style={{marginLeft:"30px",marginTop:"20px",backgroundColor:"orange"}}>Update</button>
              </form>
              {loading ? <p>Data sending...</p> : <p> </p>}
              {res? resData :<p></p>}
             

            </div>
          
          </Col>
          <Col md='9'>
            <Table responsive striped bordered hover>
              <thead>
                <tr className="table-header">
                  <td>id</td>
                  <th>Date</th>
                  <th>Tyre No</th>
                  <th>Activity</th>
                  <th>Assign</th>
                  <th>Expense</th>
                  <th>Location</th>
                  <th>Distance</th>
                  <th>Action</th> 
                  <td></td>
                </tr>
              </thead>
              <tbody>
                {data.map((item, index) => (
                  <tr key={item.id} className="table-row">
                    <td>
                      {item.id}
                    </td>
                    <td>{item.date}</td>
                    <td>{item.tyreNo}</td>
                    <td>{item.activity}</td>
                    <td>{item.assign}</td>
                    <td>{item.expense}</td>
                    <td>{item.vehicleNo}</td>
                    <td>{item.distance}</td>
                    <td>
                    <button onClick={() => handleFetchData(index)}><FaEdit /></button>
                    
                    <button onClick={() => fetchDeleteTyre(index)}><FaTrash /></button>
                    </td>
                    
                  </tr>
                  
                ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      
      
    </div>
  );
};

export default TyreJourney;

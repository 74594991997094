import React, { useState, useEffect } from 'react';
import axios from 'axios';
import AuthUser from './Authuser';
import { useNavigate } from 'react-router-dom';

const Booking=() => {
  const [bookingData, setBookingData] =useState([]);
  const [isLoading, setIsLoading] =useState(true);
  const { token, refreshToken, http, logout } = AuthUser();
  const navigate = useNavigate();

  useEffect(() => {
      if (!token) {
          // Redirect to login if no token
          navigate('/userlogin');
      }
  }, [token, navigate]);

  useEffect(()=>{
    const fetchData = async() =>{
      try{
        const response =await http.get('/api_booking_history')
        setBookingData(response.data);
        setIsLoading(false);
      }catch(error){
        console.error(error)
      }

    }
    fetchData();

  },[]);




  return (
    <div>
      {isLoading && <div className='loading'>Loading... </div>}
      {bookingData.map((bookingList) => (
        <div key={bookingList.id}>
          <h2>{bookingList.vehicle_no}</h2>
        </div>
      ))}
    
    </div>
  )
}

export default Booking

import React, {useEffect, useState } from 'react';
import axios from 'axios';

import 'react-datepicker/dist/react-datepicker.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FaCalendarAlt } from "react-icons/fa";
import { endPoint } from './EndPoint';
import './Sales.css';

import { Button, Modal } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';

import { FaEdit } from "react-icons/fa";
import { FaTrash } from "react-icons/fa";
import AuthUser from './Authuser';
import { useNavigate } from 'react-router-dom';


const BookingTable = ({ data, handleFetchData, handleDelete }) => (
  <Table responsive striped bordered hover>
      <thead>
          <tr className="table-header">
              <th>Date</th>
              <th>Vehicle No</th>
              <th>From</th>
              <th>To</th>
              <th>Charge</th>
              <th>Payment</th>
              <th>Due</th>
              <th>Name</th>
              <th>Contact No.</th>
              <th>Action</th>
          </tr>
      </thead>
      <tbody>
          {data.map((item, index) => (
              <tr key={index} className="table-row">
                  <td>{item.date}</td>
                  <td>{item.vehicleNo}</td>
                  <td>{item.from}</td>
                  <td>{item.to}</td>
                  <td>{item.charge}</td>
                  <td>{item.payment}</td>
                  <td>{item.due}</td>
                  <td>{item.name}</td>
                  <td>{item.contact}</td>
                  <td>
                      <button onClick={() => handleFetchData(index)}><FaEdit /></button>
                      <button onClick={() => handleDelete(index)}><FaTrash /></button>
                  </td>
              </tr>
          ))}
      </tbody>
  </Table>
);



const NewBooking = () => {
  const { token, refreshToken, http, logout } = AuthUser();
  const navigate = useNavigate();

  useEffect(() => {
      if (!token) {
          // Redirect to login if no token
          navigate('/userlogin');
      }
  }, [token, navigate]);

  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
    const day = String(today.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };
  const [buttonStyle,setButtonStyle]=useState({backgroundColor:'white'})
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [isData,setIsData] =useState(true)

  const [date,setDate] = useState(getCurrentDate());
  const [cBooking,setCbooking] = useState();
  const [uBooking,setUbooking] = useState();
  const [vehicleNo,setVehicleNo] = useState('');
  const [from,setFrom] = useState('');
  const [to,setTo] = useState('');
  const [charge,setCharge] = useState('');
  const [payment,setPayment] = useState('');
  const [due,setDue] = useState('');
  const [name,setName] = useState('');
  const [contact,setContact] = useState('');
 
  
  const [id,setId] = useState('');

  const [loading,setLoading] =useState(false)
  const [res,setRes] =useState(false)

  const [dataload,setDataLoad] =useState(true)
  const [showCb,setShowCb] =useState(false)
  const [showUb,setShowUb] =useState(false)
  const [showHb,setShowHb] =useState(false)
  const Cb=() =>{
    setShowCb(true)
    setShowHb(false)
    setShowUb(false)
    
  }
  const Ub=() =>{
    setShowCb(false)
    setShowHb(false)
    setShowUb(true)
    
  }
  const Hb=() =>{
    setShowCb(false)
    setShowHb(true)
    setShowUb(false)
  }

  const fetchData = async () => {
    try {
      const response = await http.get('/api_booking_history');
     
      const resData = await response.data
      setData(resData);
      setIsData(false);
      console.log(resData)
      console.log(resData)
    } catch (error) {
      setError(error);
      setIsData(false);
    }
  };
  const fetchCbooking = async () => {
    try {
      const response = await http.get('/api_booking_current');
     
      const resData = await response.data;
      setCbooking(resData);
      setIsData(false);
      console.log(resData)
      console.log(resData)
    } catch (error) {
      setError(error);
      setIsData(false);
    }
  };

  const fetchUbooking = async () => {
    try {
      const response = await http.get('/api_booking_upcomming');
      
      const resData = await response.data;
      setUbooking(resData);
      setIsData(false);
      console.log(resData)
      console.log(resData)
    } catch (error) {
      setError(error);
      setIsData(false);
    }
  };
  
  useEffect(() => {
    fetchData();
    fetchCbooking();
    fetchUbooking();
  }, []);
 

  useEffect(() => {
    if (res) {
      const timer = setTimeout(() => {
        setRes('');
      }, 5000); // Clear the result message after 5 seconds

      return () => clearTimeout(timer); // Cleanup the timer on component unmount
    }
  }, [res]);
  

  const convertDate = (dateStr) => {
    const [day, month, year] = dateStr.split('/');
    return `${year}-${month}-${day}`;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true)
    try {
      
      const response = await http.post('/api_newBooking', {
        vehicleNo,
        date,
        from,
        to,
        name,
        contact,
        charge,
        payment,
        due,
       
      });
      console.log(response.data);
      setLoading(false)
      setVehicleNo('')
      setDate('')
      setFrom('')
      setTo('')
      setName('')
      setContact('')
      setCharge('')
      setPayment('')
      setDue('')
      fetchData()
     
      setRes(true)
      // Assuming the API returns some data upon successful submission
      // Optionally, you can redirect the user or show a success message here

    } catch (error) {
      console.error('Error submitting sales entry:', error);
      setLoading(false);
      setRes('Error submitting data');
      // Handle error, show error message to the user, etc.
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    setLoading(true)
    try {
      
      const response = await http.put('/api_updateBooking/'+id, {
        
        vehicleNo,
        date,
        from,
        to,
        name,
        contact,
        charge,
        payment,
        due,
      });
      console.log(response.data);
      setLoading(false)
      setVehicleNo('')
      setDate('')
      setFrom('')
      setTo('')
      setName('')
      setContact('')
      setCharge('')
      setPayment('')
      setDue('')
      fetchData()
      
      // Assuming the API returns some data upon successful submission
      // Optionally, you can redirect the user or show a success message here

    } catch (error) {
      console.error('Error submitting sales entry:', error);
      setLoading(false);
      setRes('Error submitting data');
      // Handle error, show error message to the user, etc.
    }
  };


  const fetchHistoryDeleteData = (index) =>{
    const selectedData = data[index];
    setId(selectedData.id)
    setVehicleNo(selectedData.vehicleNo)
    handleShow()
  }
  const fetchUpcomingDeleteData = (index2) =>{
    const selectedData = uBooking[index2];
    setId(selectedData.id)
    setVehicleNo(selectedData.vehicleNo)
    handleShow()
  }
  const fetchCurrentDeleteData = (index1) =>{
    const selectedData = cBooking[index1];
    setId(selectedData.id)
    setVehicleNo(selectedData.vehicleNo)
    handleShow()
  }

  const handleDelete = async (index) => {
    setLoading(true);
    try {
      const response = await http.delete('/api_deleteBooking/'+id);
  
      console.log('Sales data deleted successfully:', response.data);
      setLoading(false);
      
      fetchData()
      handleClose()
      // Optionally handle successful deletion (e.g., update UI, redirect)
    } catch (error) {
      console.error('Error deleting sales data:', error);
  
      // Handle specific error types (e.g., network errors)
      if (error.response) {
        // Server responded with a status code that falls outside the range of 2xx
        console.error('Server error:', error.response.data);
        setRes('Error deleting data (server error)');
      } else if (error.request) {
        // The request was made but no response was received
        console.error('Network error:', error.request);
        setRes('Error deleting data (network error)');
      } else {
        // Something happened in setting up the request that triggered an Error
        console.error('Error:', error.message);
        setRes('Error deleting data (unknown error)');
      }
  
      setLoading(false);
    }
  };


  const handleHistoryFetchData = (index) => {
    const selectedData = data[index]; // Get the data of the selected row from history
    setVehicleNo(selectedData.vehicleNo);
    setDate(convertDate(selectedData.date))
    setFrom(selectedData.from)
    setTo(selectedData.to)
    setName(selectedData.name)
    setContact(selectedData.contact)
    setCharge(selectedData.charge)
    setPayment(selectedData.payment)
    setDue(selectedData.due)
    setId(selectedData.id);

    console.log(selectedData.id)
};

const handleCurrentFetchData = (index1) => {
    const selectedData = cBooking[index1]; // Get the data of the selected row from current bookings
    setVehicleNo(selectedData.vehicleNo);
    setDate(convertDate(selectedData.date))
    setFrom(selectedData.from)
    setTo(selectedData.to)
    setName(selectedData.name)
    setContact(selectedData.contact)
    setCharge(selectedData.charge)
    setPayment(selectedData.payment)
    setDue(selectedData.due)
    setId(selectedData.id);

    console.log(selectedData.id)
};

const handleUpcommingFetchData = (index2) => {
    const selectedData = uBooking[index2]; // Get the data of the selected row from upcoming bookings
    setVehicleNo(selectedData.vehicleNo);
    setDate(convertDate(selectedData.date))
    setFrom(selectedData.from)
    setTo(selectedData.to)
    setName(selectedData.name)
    setContact(selectedData.contact)
    setCharge(selectedData.charge)
    setPayment(selectedData.payment)
    setDue(selectedData.due)
    setId(selectedData.id);

    console.log(selectedData.id)
};

const activeStyle = {
  backgroundColor: 'orange',
  color:'black'
};

const inactiveStyle = {
  backgroundColor: 'white',
  color: 'black'
};
  


  if (isData) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <div className="container-fluid" >
      
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Are you delete </Modal.Title>
          </Modal.Header>
          <Modal.Body>{vehicleNo}</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="danger" onClick={handleDelete}>
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
        <Row className="justify-content-md-center">
          <Col md='3' >
            <div className='container' >
              <h3>Booking Registration</h3>
              <form onSubmit={handleSubmit}>
                <table>
                  <tbody className='salesEntry'>
                    <tr>
                      <td>Entry Date:</td>
                    
                      <td>
                        <input type="date"  name='date' value={date} onChange={e => setDate(e.target.value)}/>
                      
                      </td>
                    
                    </tr>
                    <tr>
                      <td>Vehicle No:</td>
                      <td>
                        <input
                          type="text"
                          name="vehicleNo"
                          pattern="[A-Z0-9]+"
                          value={vehicleNo}
                          onChange={(e) => setVehicleNo(e.target.value.toUpperCase())}
                          required
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>From:</td>
                      <td>
                        <input
                          type="text"
                          name="from"
                          
                          value={from}
                          onChange={(e) => setFrom(e.target.value)}
                          required
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>To:</td>
                      <td>
                        <input
                          type="text"
                          name="to"
                          
                          value={to}
                          onChange={(e) => setTo(e.target.value)}
                          required
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Charge:</td>
                      <td>
                        <input
                          type="number"
                          name="charge"
                          pattern='[0-9]'
                          value={charge}
                          onChange={(e) => setCharge(e.target.value)}
                          required
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Payment:</td>
                      <td>
                        <input
                          type="number"
                          name="payment"
                          
                          value={payment}
                          onChange={(e) => setPayment(e.target.value.toUpperCase())}
                          required
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Due:</td>
                      <td>
                        <input
                          type="number"
                          name="due"
                          pattern='[0-9]'
                          value={due}
                          onChange={(e) => setDue(e.target.value)}
                          required
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Name :</td>
                      <td>
                        <input
                          type="text"
                          name="name"
                          
                          value={name}
                          onChange={(e) => setName(e.target.value.toUpperCase())}
                          required
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Contact:</td>
                      <td>
                        <input
                          type="number"
                          name="contact"
                          pattern='[0-9]'
                          value={contact}
                          onChange={(e) => setContact(e.target.value)}
                          required
                        />
                      </td>
                    </tr>
                    
                  </tbody>
                </table>
                <button type="submit" style={{marginTop:"20px"}}>Save</button>
                <button onClick={handleUpdate} style={{marginLeft:"30px",marginTop:"20px",backgroundColor:"orange"}}>Update</button>
              </form>
              {loading ? <p>Data sending...</p> : <h1></h1>}
              {res? <p>Data saved successfuly</p>:<></>}

            </div>
          
          </Col>
          <Col md="9">
            <div>
                <p>
                    <button onClick={Cb} style={showCb ? activeStyle : inactiveStyle}>Current Booking</button>
                    <button onClick={Ub} style={showUb ? activeStyle : inactiveStyle}>Upcoming Booking</button>
                    <button onClick={Hb} style={showHb ? activeStyle : inactiveStyle}>History</button>
                </p>
                {showHb && (
                    <BookingTable
                        data={data}
                        handleFetchData={handleHistoryFetchData}
                        handleDelete={fetchHistoryDeleteData}
                    />
                )}
                {showCb && (
                    <BookingTable
                        data={cBooking}
                        handleFetchData={handleCurrentFetchData}
                        handleDelete={fetchCurrentDeleteData}
                    />
                )}
                {showUb && (
                    <BookingTable
                        data={uBooking}
                        handleFetchData={handleUpcommingFetchData}
                        handleDelete={fetchUpcomingDeleteData}
                    />
                )}
            </div>
        </Col>
        </Row>
      
      
    </div>
  );
};

export default NewBooking;

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Bs0SquareFill } from "react-icons/bs";
import { FcApproval } from "react-icons/fc";
import { FcLeave  } from "react-icons/fc";
import './Home.css'
import { FcAlarmClock } from "react-icons/fc";
import AuthUser from './Authuser';
import { useNavigate } from 'react-router-dom';


function Home() {
  const { token, refreshToken, http, logout } = AuthUser();
  const navigate = useNavigate();

  useEffect(() => {
      if (!token) {
          // Redirect to login if no token
          navigate('/userlogin');
      }
  }, [token, navigate]);
  const [docData, setDocData] = useState([]);
  const [bookingData,setBookingData] =useState([])
  const [servicingAmount, setServicingAmount] =useState([])
  const [resoleTyre, setResoleTyre] =useState([])
  const [newTyre,setNewTyre]=useState([])
  const [dailyReport,setDailyReport]=useState([])



  const bookingLength = bookingData.length;

  const [dueData,setDueData] =useState([])
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await http.get('/api_doc_home');
        setDocData(response.data);
        
        const bookingRes =await http.get('/api_booking_current');  
        setBookingData(bookingRes.data)
        
        const due =await http.get('/api_bookingDue')
        setDueData(due.data)
        console.log(response.data,bookingRes.data);

        const srAmount =await http.get('/api_servicing_vehicle_list')
        setServicingAmount(srAmount.data)
        console.log(srAmount.data);

        const resoleT =await http.get('/api_today_resoleTyre')
        setResoleTyre(resoleT.data)
        console.log(resoleT.data);

        const newT =await http.get('/api_newTyre_today_expense')
        setNewTyre(newT.data)
        console.log(newT.data);

       

        setIsLoading(false);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);



    
return (

<div className='dbody'>
  {isLoading && <div className='loader'>Loading...</div>} 
  
  {dailyReport.map((report_data) => (
    <div key={report_data.id}>
    
    </div>
  ))}
  



  <div className="container">
    <div className="row">
      <div className="col-md-5 table-responsive" id='pdoc'>
        <h6>Panding Document</h6>
        <table className='table table-bordered'>
          <thead>
            <tr>
              <th scope="col">Category</th>
              <th scope="col">Status</th>
              <th scope="col">Category</th>
              <th scope="col">Status</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                {docData.map((data) => (
                  <div key={data.id}>
                    <p>
                      {data.no_of_ins > 0 ? <FcAlarmClock /> : <FcApproval />} Insurance
                        
                    </p>
                    
                  </div>
                ))}
                  
              </td>
              <td className='status'>
                {docData.map((data) => (
                    <div key={data.id}>
                      {data.no_of_ins}
                    </div>
                  ))}
              </td>
              <td>
                {docData.map((data) => (
                  <div key={data.id}>
                    <p>
                      {data.no_of_pollution > 0 ? <FcAlarmClock /> : <FcApproval />} Pollution
                        
                    </p>
                    
                  </div>
                ))}
                  
              </td>
              <td className='status'>
                {docData.map((data) => (
                    <div key={data.id}>
                      {data.no_of_pollution}
                    </div>
                  ))}
              </td>
            </tr>
            <tr>
              <td>
                {docData.map((data) => (
                  <div key={data.id}>
                    <p>
                      {data.no_of_fitness > 0 ? <FcAlarmClock /> : <FcApproval />} Fitness
                        
                    </p>
                    
                  </div>
                ))}
                  
              </td>
              <td className='status'>
                {docData.map((data) => (
                    <div key={data.id}>
                      {data.no_of_fitness}
                    </div>
                  ))}
              </td>
              <td>
                {docData.map((data) => (
                  <div key={data.id}>
                    <p>
                      {data.no_of_road_tax > 0 ? <FcAlarmClock /> : <FcApproval />} Road Tax
                        
                    </p>
                    
                  </div>
                ))}
                  
              </td>
              <td className='status'>
                {docData.map((data) => (
                    <div key={data.id}>
                      {data.no_of_road_tax}
                    </div>
                  ))}
              </td>
            </tr>
            
            <tr>
              <td>
                {docData.map((data) => (
                  <div key={data.id}>
                    <p>
                      {data.no_of_permit > 0 ? <FcAlarmClock /> : <FcApproval />} Permit
                        
                    </p>
                    
                  </div>
                ))}
                  
              </td>
              <td className='status'>
                {docData.map((data) => (
                    <div key={data.id}>
                      {data.no_of_permit}
                    </div>
                  ))}
              </td>
              <td>
                {docData.map((data) => (
                  <div key={data.id}>
                    <p>
                      {data.no_of_csign > 0 ? <FcAlarmClock /> : <FcApproval />} C/Sign
                        
                    </p>
                    
                  </div>
                ))}
                  
              </td>
              <td className='status'>
                {docData.map((data) => (
                    <div key={data.id}>
                      {data.no_of_csign}
                    </div>
                  ))}
              </td>
            </tr>
            <tr>
              <td>
                {docData.map((data) => (
                  <div key={data.id}>
                    <p>
                      {data.no_of_retro_tape > 0 ? <FcAlarmClock /> : <FcApproval />} Retro Tape
                        
                    </p>
                    
                  </div>
                ))}
                  
              </td>
              <td className='status'>
                {docData.map((data) => (
                    <div key={data.id}>
                      {data.no_of_retro_tape}
                    </div>
                  ))}
              </td>
              <td>
                {docData.map((data) => (
                  <div key={data.id}>
                    <p>
                      {data.no_of_sld > 0 ? <FcAlarmClock /> : <FcApproval />} SLD
                        
                    </p>
                    
                  </div>
                ))}
                  
              </td>
              <td className='status'>
                {docData.map((data) => (
                    <div key={data.id}>
                      {data.no_of_sld}
                    </div>
                  ))}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="col-md-1"></div>
      <div className="col-md-5" id='pdoc'>
          <h6>Booking</h6>
          <div className='cbooking'>
              <p > Current Booking = {bookingLength}</p>
                {dueData.map((d_data) => (
                  <div key={d_data.id}>
                   <p>Due amount = {d_data.due}/-</p> 
                  </div>
                ))}
              
              <table className="table table-borderless" >
                <thead></thead>
                <tbody>
                  <tr>
                    <td>Booking List</td>
                    <td>Due</td>
                  </tr>
                  <tr>
                  
                    <td><img src={require('../images/list.png') } alt=""/></td>
                    <td><img src={require('../images/due.png')} alt=""/></td>
                        
                  </tr>

                </tbody>
                  
              </table>
          </div>
      </div>

      <div className="col-md-5" id='pdoc'>
            <h6 >Expense-1</h6>
            <table className="table table-borderless table-responsive" id='bt'>
              <thead></thead>
              <tbody>
                <tr>
                    <td><img width="40" height="40" src={require('../images/bus_ser.png')} alt="support"/> Servicing</td>
                    <td>
                    {servicingAmount.map((sr_data) => (
                      <div key="1">
                        {sr_data.amount}
                      </div>
                    ))}

                    </td>
                </tr>
                <tr>
                    <td><img src={require('../images/tyre.png')} alt="" /> New Tyre</td>
                    <td>
                      {newTyre.map((nt_data) => (
                        <div key={nt_data.count}>
                          <p>{nt_data.expense} ({nt_data.count})</p>
                        </div>
                      ))}
                      
                    </td>
                </tr>
                <tr>
                    <td><img src={require('../images/tyre_ser.png')} alt=""/ > Resole Tyre</td>
                    <td>
                      {resoleTyre.map((rt_data) => (
                        <div key={rt_data.count}>
                          <p>
                          {rt_data.totalExpense} ({rt_data.count})
                          </p>
                              
                        </div>
                      ))}
                    </td>
                </tr>

              </tbody>
               
            </table>
      </div>
      <div className="col-md-1"></div>
      <div className="col-md-5" id='pdoc' >
          <h6 >Daily Expense</h6>
          <table className="table table-borderless table-responsive" id='bt'>
            <thead></thead>
            <tbody>
              <tr>
                  <td> <img src={require('../images/diesel.png')} alt="" /> Diesel Qty</td>
                  <td> 
                    
                    {dailyReport.map((report_data) => (
                      <div key={report_data.id}>
                        {report_data.total_deisel} ltr.
                      </div>
                    ))}

                  </td>
              </tr>
              <tr>
                  <td> <img src={require('../images/price.png')} alt="" /> Diesel Price </td>
                  <td>
                      {dailyReport.map((report_data) => (
                        <div key={report_data.id}>
                          {report_data.total_deisel_amount} Rs.
                        </div>
                      ))}

                  </td>
              </tr>
              <tr>
                  <td>Other Expenses</td>
                  <td>
                      {dailyReport.map((report_data) => (
                        <div key={report_data.id}>
                          {report_data.total_expense} Rs.
                        </div>
                      ))}
                    
                  </td>
              </tr>

            </tbody>
              
          </table>

      </div>
    </div>
  </div>
  <br />
  <br />
  
</div>    
  
  
)
}

export default Home

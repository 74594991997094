import React from 'react'

function ServicingItem() {
  return (
    <div>
      ServicingItem
    </div>
  )
}

export default ServicingItem

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import AuthUser from './Authuser';
import { useNavigate } from 'react-router-dom';
import { Row, Col, Button, Table } from 'react-bootstrap';
import { FcSearch } from "react-icons/fc";

const LocationDashboard = () => {
  const [locationData, setLocationData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const { token, refreshToken, http, logout } = AuthUser();
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState("");
  const [currentStatus, setCurrentStatus] = useState([]);
  const [running,setRunning]=useState('');
  const [stopped,setStopped] =useState("");

  const searchData = () => {
    // Implement search logic here
  };

  const fetchData = async () => {
    try {
      const response = await http.get('/currentGpsData');
      setCurrentStatus(response.data.results || []);
      setIsLoading(false);
      setRunning(response.data.running)
      setStopped(response.data.stopped)
      console.log(response.data.results)
      console.log(response.data.running)
    } catch (error) {
      console.error(error);
      setError(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <div className='container' style={{ marginTop: "-20px" }}>
        <Row style={{ textAlign: "center" }}>
          <Col md="5">
            <input
              type="text"
              name="name"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value.toUpperCase())}
              required
              placeholder="Search..."
            />
            <Button variant="light" onClick={searchData}>
              <FcSearch style={{ fontSize: "30px" }} />
            </Button>
          </Col>
          <Col md="7">
            <Button variant="outline-success">{running}-Running</Button>
            <Button variant="outline-danger">{stopped}-Stop</Button>
            <Button variant="outline-warning">0-OverSpeed</Button>
            <Button variant="outline-secondary">0-Idle</Button>
            <Button variant="outline-dark">0-Unreachable</Button>
          </Col>
        </Row>
        <hr style={{ marginTop: "-8px" }} />
      </div>
      <div className="container-fluid" style={{ marginTop: "-30px" }}>
        <Row>
          <Col md="6" style={{ height: "500px", backgroundColor: "lightgreen" }}>
            <Table striped bordered hover>
              <thead style={{ backgroundColor: "lightblue", textAlign: "center" }}>
                <tr>
                  <th>Vehicle No.</th>
                  <th>Speed</th>
                  <th>Location</th>
                  <th>Last Update</th>
                </tr>
              </thead>
              <tbody>
                {currentStatus.length > 0 ? currentStatus.map((item, index) => (
                  <tr key={index}>
                    <td>{item.vehicleNo}</td>
                    <td>{item.speed}</td>
                    <td>{item.area}</td>
                    <td>{item.date}</td>
                  </tr>
                )) : (
                  <tr>
                    <td colSpan="4" style={{ textAlign: "center" }}>No Data Available</td>
                  </tr>
                )}
              </tbody>
            </Table>
          </Col>
          <Col md="4">
            {/* Additional content or functionality can go here */}
          </Col>
        </Row>
      </div>
    </>
  );
};

export default LocationDashboard;

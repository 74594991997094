import React, {useEffect, useState } from 'react';
import axios from 'axios';
import Datepicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FaCalendarAlt } from "react-icons/fa";
import { endPoint } from './EndPoint';
import './Sales.css';


import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';

import { FaEdit } from "react-icons/fa";
import { FaTrash } from "react-icons/fa";
import { format } from 'date-fns';
import AuthUser from './Authuser';
import { useNavigate } from 'react-router-dom';


const EmployeeRegistration = () => {
  const { token, refreshToken, http, logout } = AuthUser();
  const navigate = useNavigate();

  useEffect(() => {
      if (!token) {
          // Redirect to login if no token
          navigate('/userlogin');
      }
  }, [token, navigate]);
  const [data, setData] = useState([]);
  const [ownerData, setOwnerData] = useState([]);
  const [userId, setUserId] = useState([]);
  const [error, setError] = useState(null);
  const [isData,setIsData] =useState(true)

  const [name,setName] = useState('');
  const [contact,setContact] = useState('');
  const [address,setAddress] = useState('');
  const [email,setEmail] = useState('');
  const [gender,setGender] = useState('');
  const [dob,setDob] = useState('2024-01-01');
  const [doj,setDoj] = useState('2024-01-01');
  const [post,setPost] = useState('');
  const [salary,setSalary] = useState('');
  const [licenceNo,setLicenceNo] = useState();
  const [validity,setValidity] = useState('2024-01-01');
  const [aadhar,setAadhar] = useState();
  const [password,setpassword] = useState();
  const [driverId,setDriverId] = useState();

  const [id,setId] = useState('');

  const [loading,setLoading] =useState(false)
  const [res,setRes] =useState(false)

  const [dataload,setDataLoad] =useState(true)
  
  const fetchData = async () => {
    try {
      const response = await http.get('/api_employee_reg');
     
      const resData = await response.data;
      setData(resData);
      setIsData(false);
      console.log(resData)
     
    } catch (error) {
      setError(error);
      setIsData(false);
      navigate("/")
    }
  };

  const fetchoOwnerData = async () => {
    try {
      const response = await http.get('/api_userProfile');
     
      const resData = await response.data;
      
      setIsData(false);
      console.log(resData[0].userId)
      setUserId(resData[0].userId)
      
    } catch (error) {
      setError(error);
      setIsData(false);
    }
  };
  
  useEffect(() => {
    // Define the async function to fetch data
    

    // Call the fetch function
    fetchData();
    fetchoOwnerData()
  }, []);
 

  useEffect(() => {
    if (res) {
      const timer = setTimeout(() => {
        setRes('');
      }, 5000); // Clear the result message after 5 seconds

      return () => clearTimeout(timer); // Cleanup the timer on component unmount
    }
  }, [res]);
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true)
    const uId=userId+'@'+driverId
    setOwnerData(uId)
    console.log(uId)
    try {
      
      const response = await http.post('/api_employee_reg', {
        name,
        contact,
        address,
        email,
        gender,
        doj,
        dob,
        post,
        licenceNo,
        validity,
        aadharNo:aadhar,
        driverId:uId,
        password,
        salary
      });
      console.log(response.data);
      setLoading(false)
      setName('')
      setDriverId('')
      setContact('')
      setContact('')
      setAddress('')
      setEmail('')
      setGender('')
      setDob('')
      setDoj('')
      setPost('')
      setSalary('')
      setLicenceNo('')
      setValidity('')
      setAadhar('')
     
      setRes(true)
      // Assuming the API returns some data upon successful submission
      // Optionally, you can redirect the user or show a success message here
      fetchData()
    } catch (error) {
      console.error('Error submitting sales entry:', error);
      setLoading(false);
      setRes('Error submitting data');
      // Handle error, show error message to the user, etc.
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    setLoading(true)

    try {
      
      const response = await http.put('/api_employee_update/'+driverId, {
        name,
        contact,
        address,
        email,
        gender,
        doj,
        dob,
        post,
        licenceNo,
        validity,
        aadharNo:aadhar,
        driverId,
        password,
        salary
      });
      console.log(response.data);
      setLoading(false)
      setName('')
      setDriverId('')
      setContact('')
      
      setAddress('')
      setEmail('')
      setGender('')
      setDob('')
      setDoj('')
      setPost('')
      setSalary('')
      setLicenceNo('')
      setValidity('')
      setAadhar('')
      setRes("data updated")
      // Assuming the API returns some data upon successful submission
      // Optionally, you can redirect the user or show a success message here
      fetchData()
    } catch (error) {
      console.error('Error submitting sales entry:', error);
      setLoading(false);
      setRes('Error submitting data');
      // Handle error, show error message to the user, etc.
    }
  };


  const handleDelete = async (index) => {
    setLoading(true);
    const salesDataToDelete = data[index]; // Use a more descriptive name
    setId(salesDataToDelete.id);
    const dId= salesDataToDelete.driverId
    console.log(dId)
  
    try {
      const response = await http.delete('/api_employee_delete/'+dId);
  
      console.log('Sales data deleted successfully:', response.data);
      setLoading(false);
      setRes('Sales data deleted');
      fetchData()
      // Optionally handle successful deletion (e.g., update UI, redirect)
    } catch (error) {
      console.error('Error deleting sales data:', error);
  
      // Handle specific error types (e.g., network errors)
      if (error.response) {
        // Server responded with a status code that falls outside the range of 2xx
        console.error('Server error:', error.response.data);
        setRes('Error deleting data (server error)');
      } else if (error.request) {
        // The request was made but no response was received
        console.error('Network error:', error.request);
        setRes('Error deleting data (network error)');
      } else {
        // Something happened in setting up the request that triggered an Error
        console.error('Error:', error.message);
        setRes('Error deleting data (unknown error)');
      }
  
      setLoading(false);
    }
  };

  const formatDate = (dateString) => {
    const [day, month, year] = dateString.split('/');
    const date = new Date(`${year}-${month}-${day}`);
    return format(date, 'yyyy-MM-dd');
  };

  const handleFetchData = (index) => {
    const selectedData = data[index]; // Get the data of the selected row
    setName('')
    setDriverId(selectedData.driverId)
    setContact(selectedData.contact)
    setName(selectedData.name)
    setpassword(selectedData.password)
    setAddress(selectedData.address)
    setEmail(selectedData.email)
    setGender(selectedData.gender)
    setDob(formatDate(selectedData.dob))
    setDoj(formatDate(selectedData.doj))
    setPost(selectedData.post)
    setSalary(selectedData.salary)
    setLicenceNo(selectedData.licenceNo)
    setValidity(formatDate(selectedData.validity))
    setAadhar(selectedData.aadharNo)
    setId(selectedData.id);
    
    console.log(selectedData.id)
  };



  


  if (isData) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <div className="container-fluid" >    
        <Row className="justify-content-md-center">
          <Col md='3' >
            <div className='container' >
              <h3>Employee Reg.</h3>
              <form onSubmit={handleSubmit}>
                <table>
                  <tbody className='salesEntry'>
                    
                    <tr>
                      <td>Name :</td>
                      <td>
                        <input
                          type="text"
                          name="name"
                          value={name}
                          onChange={(e) => setName(e.target.value.toUpperCase())}
                          required
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>contact:</td>
                      <td>
                        <input
                          type="number"
                          name="contact"
                          pattern="[0-9]"
                          value={contact}
                          onChange={(e) => setContact(e.target.value)}
                          required
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>address:</td>
                      <td>
                        <input
                          type="text"
                          name="address"
                          value={address}
                          onChange={(e) => setAddress(e.target.value.toUpperCase())}
                          required
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>email:</td>
                      <td>
                        <input
                          type="text"
                          name="email"
                         
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          required
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>gender:</td>
                      <td>
                        <input
                          type="text"
                          name="gender"
                          
                          value={gender}
                          onChange={(e) => setGender(e.target.value.toUpperCase())}
                          required
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>doj :</td>
                      <td>
                        <input
                          type="date"
                          name="doj"
                          
                          value={doj}
                          onChange={(e) => setDoj(e.target.value)}
                          required
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Dob:</td>
                      <td>
                        <input
                          type="date"
                          name="driver"
                          
                          value={dob}
                          onChange={(e) => setDob(e.target.value.toUpperCase())}
                          required
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>post :</td>
                      <td>
                        <input
                          type="text"
                          name="post"
                       
                          value={post}
                          onChange={(e) => setPost(e.target.value.toUpperCase())}
                          required
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>salary :</td>
                      <td>
                        <input
                          type="number"
                          name="salary"
                          pattern='[0-9]'
                          value={salary}
                          onChange={(e) => setSalary(e.target.value)}
                          required
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>LicenceNo :</td>
                      <td>
                        <input
                          type="text"
                          name="licenceNo"
                         
                          value={licenceNo}
                          onChange={(e) => setLicenceNo(e.target.value)}
                          required
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>validity :</td>
                      <td>
                        <input
                          type="date"
                          name="validity"
                         
                          value={validity}
                          onChange={(e) => setValidity(e.target.value)}
                          required
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>aadharNo :</td>
                      <td>
                        <input
                          type="number"
                          name="adhaar"
                          pattern='[0-9]'
                          value={aadhar}
                          onChange={(e) => setAadhar(e.target.value)}
                          required
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>DriverId :</td>
                      <td>
                        <Row>
                          <Col>
                           {userId}@
                          </Col>
                          <Col>
                            <input
                            type="text"
                            name="DriverId"
                            value={driverId}
                            onChange={(e) => setDriverId(e.target.value)}
                            required
                            style={{width:"70px"}}
                          />
                          </Col>
                        </Row>
                        
                       
                      </td>
                    </tr>
                    <tr>
                      <td>password :</td>
                      <td>
                        <input
                          type="text"
                          name="password"
                        
                          value={password}
                          onChange={(e) => setpassword(e.target.value)}
                          required
                        />
                      </td>
                    </tr>
                    
                  </tbody>
                </table>
                <button type="submit" style={{marginTop:"20px"}}>Save</button>
                <button onClick={handleUpdate} style={{marginLeft:"30px",marginTop:"20px",backgroundColor:"orange"}}>Update</button>
              </form>
              {loading ? <p>Data sending...</p> : <h1></h1>}
              {res? <p>Data saved successfuly</p>:<></>}

            </div>
          
          </Col>
          <Col md='9'>
            <Table responsive striped bordered hover>
              <thead>
                <tr className="table-header">
                  <th>name</th>
                  <th>contact No</th>
                  <th>address</th>
                  <th>email</th>
                  <th>gender</th>
                  <th>doj.</th>
                  <th>dob</th>
                  <th>post</th>
                  <th>salary</th>
                  <th>licenceNo</th>
                  <th>validity</th>
                  <th>aadharNo</th>
                  <th>driverId</th>
                  <th>password</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {data.map((item, index) => (
                  <tr key={index} className="table-row">
                    <td>{item.name}</td>
                    <td>{item.contact}</td>
                    <td>{item.address}</td>
                    <td>{item.email}</td>
                    <td>{item.gender}</td>
                    <td>{item.doj}</td>
                    <td>{item.dob}</td>
                    <td>{item.post}</td>
                    <td>{item.salary}</td>
                    <td>{item.licenceNo}</td>
                    <td>{item.validity}</td>
                    <td>{item.aadharNo}</td>
                    <td>{item.driverId}</td>
                    <td>{item.password}</td>
                    
                    <td>
                    <button onClick={() => handleFetchData(index)}><FaEdit /></button>
                    {/* Delete button */}
                    <button onClick={() => handleDelete(index)}><FaTrash /></button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      
      
    </div>
  );
};

export default EmployeeRegistration;


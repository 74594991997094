import React, {useEffect, useState } from 'react';
import axios from 'axios';

import { Modal, Button } from 'react-bootstrap';
import 'react-datepicker/dist/react-datepicker.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FaCalendarAlt } from "react-icons/fa";
import { endPoint } from './EndPoint';
import './Sales.css';


import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';

import { FaEdit } from "react-icons/fa";
import { FaTrash } from "react-icons/fa";
import { format } from 'date-fns';
import AuthUser from './Authuser';
import { useNavigate } from 'react-router-dom';


const TyreEntry = () => {
  const { token, refreshToken, http, logout } = AuthUser();
  const navigate = useNavigate();

  useEffect(() => {
      if (!token) {
          // Redirect to login if no token
          navigate('/userlogin');
      }
  }, [token, navigate]);

  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
    const day = String(today.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  
  const [error, setError] = useState(null);
  const [isData,setIsData] =useState(true)

  const [data, setData] = useState([]);
  const [date,setDate]=useState(getCurrentDate());
  const [tyreNo,setTyreNo] = useState('');
  const [brand,setBrand] = useState('');
  const [vendor,setVendor] = useState('');
  const [price,setPrice] = useState('');
  const [condition,setCondition] = useState('');
  const [size,setSize] = useState('');
  const [assign,setAsign] = useState('');
  const [vehicleNo,setVehicleNo] = useState('');
  
  
  const [id,setId] = useState('');

  const [loading,setLoading] =useState(false)
  const [res,setRes] =useState(false)
  const [resData,setResData] =useState('')
  const [dataload,setDataLoad] =useState(true)
  
  const fetchData = async () => {
    try {
      const response = await http.get('/api_newTyre');
      
      const resData = await response.data;
      setData(resData);
      setIsData(false);
      console.log(resData)
      console.log(resData)
    } catch (error) {
      setError(error);
      setIsData(false);
    }
  };
  useEffect(() => {
    // Define the async function to fetch data
    

    // Call the fetch function
    fetchData();
  }, []);
 

  useEffect(() => {
    if (res) {
      const timer = setTimeout(() => {
        setRes(false);
      }, 5000); // Clear the result message after 5 seconds

      return () => clearTimeout(timer); // Cleanup the timer on component unmount
    }
  }, [res]);
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    console.log(assign)
    try {
      
      const response = await http.post('/api_newTyre', {
        date,
        tyreNo,
        vendor,
        brand,
        price,
        condition,
        size,
        assign,
        vehicleNo,
       
      });
      console.log(response.data);
      setLoading(false)
      setVendor('')
      setTyreNo('')
      setBrand('')
      setPrice('')
      setCondition('')
      setSize('')
      setAsign('')
      setVehicleNo('')
      fetchData()
      setRes(true)
      setResData('Data saved successfully !!')
      // Assuming the API returns some data upon successful submission
      // Optionally, you can redirect the user or show a success message here

    } catch (error) {
      console.error('Error submitting sales entry:', error);
      setLoading(false);
      setRes('Error submitting data');
      // Handle error, show error message to the user, etc.
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    setLoading(true)
   
    
    try {
      
      const response = await http.put('/api_tyre_update/'+tyreNo, {
        id,
        date,
        tyreNo,
        vendor,
        brand,
        price,
        condition,
        size,
        assign,
        vehicleNo,
      });
      console.log(response.data);
      setLoading(false)
      setVendor('')
      setTyreNo('')
      setBrand('')
      setPrice('')
      setCondition('')
      setSize('')
      setAsign('')
      setVehicleNo('')
      fetchData()
      setRes(true)
      setResData('data updated successfully')
      // Assuming the API returns some data upon successful submission
      // Optionally, you can redirect the user or show a success message here

    } catch (error) {
      console.error('Error submitting sales entry:', error);
      setLoading(false);
      setRes('Error submitting data');
      // Handle error, show error message to the user, etc.
    }
  };

  const fetchDeleteTyre= (index) =>{
    handleShow()
    const selectedData = data[index];
    setTyreNo(selectedData.tyreNo)
    console.log(tyreNo)
    setRes(true)
    setResData('data deleted')
  }
  const handleDelete = async (index) => {
    setLoading(true);
  
    try {
      const response = await http.delete('/api_tyre_delete/'+tyreNo);
  
      console.log('Sales data deleted successfully:', response.data);
      setLoading(false);
      
      fetchData()
      handleClose()
      setRes(true)
      setResData('data deleted')
      // Optionally handle successful deletion (e.g., update UI, redirect)
    } catch (error) {
      console.error('Error deleting sales data:', error);
  
      // Handle specific error types (e.g., network errors)
      if (error.response) {
        // Server responded with a status code that falls outside the range of 2xx
        console.error('Server error:', error.response.data);
        setRes(true)
        setResData('Retry')
      } else if (error.request) {
        // The request was made but no response was received
        console.error('Network error:', error.request);
        setRes(true)
        setResData('Error deleting data (network error)');
      } else {
        // Something happened in setting up the request that triggered an Error
        console.error('Error:', error.message);
        setRes(true)
        setResData('Error deleting data (unknown error)');
      }
  
      setLoading(false);
    }
  };
  const formatDate = (dateString) => {
    const [day, month, year] = dateString.split('/');
    const date = new Date(`${year}-${month}-${day}`);
    return format(date, 'yyyy-MM-dd');
  };


  const handleFetchData = (index) => {
    const selectedData = data[index]; // Get the data of the selected row
    setVendor(selectedData.vendor)
    setTyreNo(selectedData.tyreNo)
    setBrand(selectedData.brand)
    setPrice(selectedData.price)
    setCondition(selectedData.condition)
    setSize(selectedData.size)
    setAsign(selectedData.assign)
    setVehicleNo(selectedData.vehicleNo)
    setDate(formatDate(selectedData.date))
    
    console.log(selectedData.tyreNo,date)
   
  };



  


  if (isData) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <div className="container-fluid" >
        <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Are you sure to delete?</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                   <h1>Tyre No:- {tyreNo}</h1>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Cancle
                    </Button>
                    <Button variant="danger" onClick={handleDelete}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
      
        <Row className="justify-content-md-center">
          <Col md='3' >
            <div className='container' >
              <h3>Tyre Entry</h3>
              <form onSubmit={handleSubmit}>
                <table>
                  <tbody className='salesEntry'>
                    <tr>
                      <td>Entry Date:</td>
                    
                      <td>
                        <input type="date"  name='date' value={date} onChange={e => setDate(e.target.value)}/>
                      
                      </td>
                    
                    </tr>
                    <tr>
                      <td>Tyre No:</td>
                      <td>
                        <input
                          type="text"
                          name="TyreNo"
                          pattern="[A-Z0-9]+"
                          value={tyreNo}
                          onChange={(e) => setTyreNo(e.target.value.toUpperCase())}
                          required
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Vendor:</td>
                      <td>
                        <input
                          type="text"
                          name="vendor"
                          pattern="[A-Z0-9]+"
                          value={vendor}
                          onChange={(e) => setVendor(e.target.value.toUpperCase())}
                          required
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Brand:</td>
                      <td>
                        <input
                          type="text"
                          name="brand"
                          pattern='[A-Z0-9]+'
                          value={brand}
                          onChange={(e) => setBrand(e.target.value.toUpperCase())}
                          required
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Price:</td>
                      <td>
                        <input
                          type="number"
                          name="price"
                          pattern='[0-9]'
                          value={price}
                          onChange={(e) => setPrice(e.target.value)}
                          required
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Condition :-</td>
                      <td>
                        
                        <select name="condition" id="" value={condition} onChange={(e) => setCondition(e.target.value)}>
                          
                          <option value="">select one</option>
                          <option value="NEW">New</option>                      
                          <option value="RESOLE">Resole</option>
                          <option value="USED">Used</option>

                        </select>
                          
                      </td>
                    </tr>
                    <tr>
                      <td>Size :-</td>
                      <td>
                        <input
                          type="text"
                          name="size"
                          pattern='[A-Z0-9]+'
                          value={size}
                          onChange={(e) => setSize(e.target.value.toUpperCase())}
                          required
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>assign:</td>
                      <td>
                        
                         <select name="assign" id="" value={assign} onChange={(e) => setAsign(e.target.value)}>
                          
                          <option value="">select one</option>
                          <option value="BUS">BUS</option>
                          <option value="TRUCK">TRUCK</option>
                          <option value="OTHER">OTHER</option>

                       </select>
                      </td>
                    </tr>
                    <tr>
                      <td>Address:</td>
                      <td>
                        <input
                          type="text"
                          name="vehicleNo"
                          pattern='[A-Z0-9]+'
                          value={vehicleNo}
                          onChange={(e) => setVehicleNo(e.target.value.toUpperCase())}
                          required
                          placeholder='vehicle No / store address'
                        />
                      </td>
                    </tr>
                   
                  </tbody>
                </table>
                <button type="submit" style={{marginTop:"20px"}}>Save</button>
                <button onClick={handleUpdate} style={{marginLeft:"30px",marginTop:"20px",backgroundColor:"orange"}}>Update</button>
              </form>
              {loading ? <p>Data sending...</p> : <p> </p>}
              {res? resData:<p></p>}
             

            </div>
          
          </Col>
          <Col md='9'>
            <Table responsive striped bordered hover>
              <thead>
                <tr className="table-header">
                  <th>Date</th>
                  <th>Tyre No</th>
                  <th>Vendor</th>
                  <th>Brand</th>
                  <th>Price</th>
                  <th>Condition</th>
                  <th>Size</th>
                  <th>Assign</th>
                  <th>Location</th>
                  <th>Action</th>
                 
                </tr>
              </thead>
              <tbody>
                {data.map((item, index) => (
                  <tr key={index} className="table-row">
                    <td>{item.date}</td>
                    <td>{item.tyreNo}</td>
                    <td>{item.vendor}</td>
                    <td>{item.brand}</td>
                    <td>{item.price}</td>
                    <td>{item.condition}</td>
                    <td>{item.size}</td>
                    <td>{item.assign}</td>
                    <td>{item.vehicleNo}</td>
                    
                    <td>
                    <button onClick={() => handleFetchData(index)}><FaEdit /></button>
                    {/* Delete button */}
                    <button onClick={() => fetchDeleteTyre(index)}><FaTrash /></button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      
      
    </div>
  );
};

export default TyreEntry;

import React, { useState, useEffect } from 'react';
import socketIOClient from 'socket.io-client';

const SERVER_URL = 'https://busmitra.in/';

function CoordinatesComponent() {
  const [coordinates, setCoordinates] = useState({});
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const socket = socketIOClient(SERVER_URL);

    socket.on('connect', () => {
      console.log('Connected to server');
      // Emit 'get_coordinates' event when connected to fetch latest coordinates
      socket.emit('get_coordinates', 'JH01AV0602');
    });

    socket.on('disconnect', () => {
      console.log('Disconnected from server');
    });

    socket.on('latest_coordinates', (data) => {
      if (data.error) {
        setError(data.error);
      } else {
        setCoordinates(data);
      }
      setLoading(false);
    });

    return () => {
      socket.disconnect();
      socket.off('latest_coordinates');
    };
  }, []);

  return (
    <div>
      {loading ? (
        <p>Loading...</p>
      ) : error ? (
        <p>Error: {error}</p>
      ) : (
        <p>
           lat: {coordinates.lat},{coordinates.lon}
           <br />
           Longitude:{coordinates.lon}
           <br />
           vehicleNo: {coordinates.vehicleNo}
           <br />
           time:{coordinates.time}
           
        </p>
      )}
    </div>
  );
}

export default CoordinatesComponent;
